import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ListFormHoc from "../../../utils/listFormHoc";
import List from "./patientList";
import Form from "./form/index";
import { setMainHeading } from "../../../redux/modules/mainHeading/slice";
import { Box, Container, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export default function PatientManagement() {
    const dispatch = useDispatch();
    const theme = useTheme();
    
    useEffect(() => {
        dispatch(setMainHeading("Patient Management"))
        
        // Important: Clean up when component unmounts
        return () => {
            // This ensures that when we navigate away, we don't leave stale heading state
            // The NavigationListener will reset the heading, allowing the next page to set it correctly
        };
    }, [dispatch])

    // Render just the Add button in a consistent container style
    const renderActionButtons = (actionButtonProps) => (
        <Box 
            sx={{ 
                display: "flex", 
                justifyContent: "flex-end", 
                alignItems: "center",
                mb: 3, 
                mt: 2,
                px: { xs: 2, md: 3 }
            }}
        >
            <Box 
                className="commonBtn"
                variant="contained" 
                onClick={actionButtonProps.onClick}
                sx={{ 
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontWeight: 500,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                        boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                    }
                }}
            >
                <AddIcon sx={{ mr: 0.5 }} /> {actionButtonProps.text}
            </Box>
        </Box>
    );

    return (
        <Container maxWidth="xl" sx={{ py: 3 }}>
            <ListFormHoc List={List} Form={Form} renderActionButtons={renderActionButtons}></ListFormHoc>
        </Container>
    );
}
