import { AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Stack, Typography, Badge } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRequest, currentBranch as currentBranchSlice, resetGet } from "../../redux/modules/branch/slice";
import { getRequest as getProfileRequest } from "../../redux/modules/profile/slice";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import LogoutIcon from '@mui/icons-material/Logout';
import { logoutRequest } from "../../redux/modules/auth/authSlice";
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { USER_ROLE_DEFAULT } from "../../constants/Constant";

/**
 * Shared Dashboard Header component used across all dashboard types
 * 
 * @param {Object} props Component props
 * @param {number} props.drawerWidth Width of the drawer/sidebar
 * @param {Function} props.handleDrawerToggle Function to toggle the drawer
 * @param {Array} props.settingsList List of settings menu items
 * @param {string} props.userRole User role
 * @param {string} props.editProfilePath Path to the edit profile page
 * @returns {JSX.Element} Dashboard header component
 */
const DashboardHeader = ({ 
  drawerWidth, 
  handleDrawerToggle, 
  settingsList = [
    {
      navIcon: <InboxIcon />,
      navText: "Edit Profile",
      navLink: "admin/edit-profile",
    },
    {
      navIcon: <InboxIcon />,
      navText: "Settings",
      navLink: "admin/settings",
    }
  ],
  userRole,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { getRes, currentBranch } = useSelector(state => state.branch);
  let { mainHeading } = useSelector(state => state.mainHeading);
  let { getRes: profileData } = useSelector(state => state.profile);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElBranch, setAnchorElBranch] = useState(null);

  const filteredSettingList = userRole === USER_ROLE_DEFAULT.PATIENT
    ? settingsList.filter((column) => column.navText !== "Settings")
    : settingsList;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenBranchMenu = (event) => {
    setAnchorElBranch(event.currentTarget);
  };
  const handleCloseBranchMenu = () => {
    setAnchorElBranch(null);
  };

  useEffect(() => {
    dispatch(getRequest());
    dispatch(getProfileRequest())
  }, [dispatch]);

  useEffect(() => {
    if (getRes?.success && !getRes?.data?.length > 0 && location.pathname !== "/add/branch") {
      navigate("/add/branch")
      dispatch(resetGet());
    }
  }, [getRes, location, navigate, dispatch]);

  const ChangeBranch = (data) => {
    dispatch(currentBranchSlice(data))
  }

  const Toolbar = styled('div')(({ theme }) => ({
    padding: "30px",
    paddingRight: "40px",
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
    '@media (max-width:768px)': {
      padding: '20px',
      paddingRight: '30px',
    },
    '@media (min-width:1920px)': {
      padding: '30px 40px',
      paddingRight: '60px',
    },
    '@media (min-width:2560px)': {
      padding: '40px 60px',
      paddingRight: '80px',
    },
  }));

  return (
    <AppBar 
      color="transparent" 
      position="fixed" 
      sx={{ 
        width: { 
          sm: '100%',
          md: '100%',
          lg: '100%',
          xl: `calc(100% - ${drawerWidth}px)` 
        }, 
        ml: { lg: `${drawerWidth}px`, md: '0' }, 
        boxShadow: "unset",
        maxWidth: '100%',
        pr: { xs: 2, lg: 3 }
      }}
    >
      <Toolbar>
        <Stack direction="row" spacing={1} sx={{ 
          width: "100%", 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "space-between",
          pr: { xs: 2, sm: 2, md: 3 }
        }} className="header-wrapper">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'block', md: 'block', lg: 'block', xl: "none" }, }}
            >
              <MenuIcon />
            </IconButton>
            <Typography color={"primary.dark"} variant="h3" noWrap component="div"
              sx={{
                fontWeight: "600",
                fontSize: { lg: "32px", md: "24px", sm: "24px", xs: "20px" },
              }}>
              {mainHeading}
            </Typography>
          </Box>

          <Box sx={{ 
            display: "flex", 
            flexDirection: "row", 
            alignItems: "center", 
            justifyContent: 'flex-end',
            position: 'relative',
            zIndex: 1200,
            minWidth: { xs: '70px', sm: '180px' }
          }} className="header-icons">
            {userRole !== USER_ROLE_DEFAULT.PATIENT && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <IconButton onClick={handleOpenBranchMenu} sx={{ position: 'relative', width: "100%", padding: "12px 12px", "&:hover": { backgroundColor: "unset" } }}>
                  <Avatar sx={{ height: "22px", maxWidth: "32px", borderRadius: "4px", marginRight: "10px" }} alt={profileData?.data?.firstName} src={require('../../assets/images/Flag Icon.png')} />
                  <Typography noWrap sx={{ fontSize: "18px", fontWeight: "600", color: "primary.dark", marginRight: "10px", display: { sm: "block", xs: "none" } }}>{currentBranch?.name}</Typography>
                  <Box sx={{ transform: anchorElBranch ? "rotate(-180deg)" : "rotate(0deg)", height: "30px" }}>
                    <KeyboardArrowDownIcon sx={{ color: 'primary.dark', fontSize: '30px' }}></KeyboardArrowDownIcon>
                  </Box>
                </IconButton>
                <Menu
                  className="branchMenu"
                  anchorEl={anchorElBranch}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElBranch)}
                  onClose={handleCloseBranchMenu}
                  marginThreshold={0}
                  slotProps={{
                    paper: { 
                      sx: { 
                        maxWidth: '100%',
                        width: 'auto',
                        overflowX: 'visible',
                        mt: 2
                      } 
                    }
                  }}
                >
                  {getRes?.data?.map((item) => (
                    <MenuItem key={item._id} onClick={() => { ChangeBranch({ name: item.name, id: item._id }); handleCloseBranchMenu() }}>
                      <Button color="primary">
                        {item.name}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}

            <Box sx={{ flexGrow: 0, position: 'relative' }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ width: "100%", padding: "12px 12px", "&:hover": { backgroundColor: "unset" } }}>
                <Avatar sx={{ height: "28px", width: "28px", borderRadius: "4px", marginRight: "10px" }} src={require('../../assets/images/Avatar.png')} />
                <Typography noWrap sx={{ fontSize: "18px", fontWeight: "600", color: "primary.dark", marginRight: "10px", display: { sm: "block", xs: "none" } }}>{profileData?.data?.firstName} {profileData?.data?.lastName}</Typography>
                <Box sx={{ transform: anchorElUser ? "rotate(-180deg)" : "rotate(0deg)", height: '30px' }}>
                  <KeyboardArrowDownIcon sx={{ color: 'primary.dark', fontSize: '30px' }}></KeyboardArrowDownIcon>
                </Box>
              </IconButton>
              <Menu
                className="adminMenu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                marginThreshold={0}
                slotProps={{
                  paper: { 
                    sx: { 
                      maxWidth: '100%',
                      width: 'auto',
                      overflowX: 'visible',
                      mt: 2
                    } 
                  }
                }}
              >
                {filteredSettingList.map((item) => (
                  <MenuItem key={item.navText} onClick={handleCloseUserMenu}>
                    <Button color="primary" startIcon={item.navIcon} component={Link} to={item.navLink}>
                      {item.navText}
                    </Button>
                  </MenuItem>
                ))}
                <MenuItem onClick={handleCloseUserMenu}>
                  <Button color="primary" startIcon={<LogoutIcon />} onClick={() => { dispatch(logoutRequest()) }} >
                    Logout
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader; 