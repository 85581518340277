import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setShowList } from '../redux/modules/showList/slice';

const ListFormHoc = ({ List, Form, renderActionButtons }) => {
  const dispatch = useDispatch();
  const { currentBranch } = useSelector(state => state.branch)
  const { showList } = useSelector(state => state.reduxShowList)
  const [itemData, setItemData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  
  const toggleList = () => { 
    dispatch(setShowList(!showList));
  };
  
  // Provide button properties to parent component
  const getActionButtonProps = () => ({
    icon: showList ? <AddIcon /> : <CloseIcon />,
    text: showList ? "Add" : "Cancel",
    onClick: () => { 
      toggleList(); 
      setItemData({});
    }
  });
  
  useEffect(() => {
    if (currentBranch) {
      dispatch(setShowList(true));
    }
  }, [dispatch, currentBranch])
  
  return (
    <>
      {renderActionButtons && renderActionButtons(getActionButtonProps())}
      {showList 
        ? <List setItemData={setItemData} toggleList={toggleList} itemData={itemData} setCurrentPage={setCurrentPage} currentPage={currentPage} /> 
        : <Form itemData={itemData} setItemData={setItemData} toggleList={toggleList} />}
    </>
  )
}

export default ListFormHoc;