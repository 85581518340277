import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetMainHeading } from '../redux/modules/mainHeading/slice';

/**
 * NavigationListener component that listens for route changes
 * and resets the main heading when navigating to ensure 
 * proper header title updates.
 */
const NavigationListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  useEffect(() => {
    // When the route changes, reset the main heading
    // This will allow the new page to set its own heading
    dispatch(resetMainHeading());
  }, [location.pathname, dispatch]);
  
  return null; // This component doesn't render anything
};

export default NavigationListener; 