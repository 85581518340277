import ListFormHoc from "../../../utils/listFormHoc";
import List from "./list";
import Form from "./form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMainHeading } from "../../../redux/modules/mainHeading/slice";
import { Box } from "@mui/material";

export default function UserManagement() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setMainHeading("User Management"))
    }, [dispatch])
    
    // Render just the Add button in a consistent container style
    const renderActionButtons = (actionButtonProps) => (
        <Box 
            sx={{ 
                display: "flex", 
                justifyContent: "flex-end", 
                alignItems: "center",
                mb: 3, 
                mt: 2
            }}
        >
            <Box 
                className="commonBtn"
                variant="contained" 
                onClick={actionButtonProps.onClick}
                sx={{ 
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer"
                }}
            >
                {actionButtonProps.icon} {actionButtonProps.text}
            </Box>
        </Box>
    );
    
    return (
        <>
            <ListFormHoc List={List} Form={Form} renderActionButtons={renderActionButtons}></ListFormHoc>
        </>
    );
}
