import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
    Grid, Card, Box, Typography, FormLabel, Button, Table, TableHead, TableRow, TableBody, TextField, TableContainer, Paper, Input, FormHelperText, Container
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getIdRequest, resetgetPatientOrders, resetGetId } from '../../../redux/modules/orders/slice';
import { postRequest, putRequest, resetPost, resetPut } from '../../../redux/modules/invoice/slice';
import moment from 'moment';
import { setMainHeading } from '../../../redux/modules/mainHeading/slice';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const tableHeading = ["Sno.", "Order Date", "OrderID", "Exam", "Unit/Quantity", "Unit Price ($)", "Cost ($)"];

const validationSchema = yup.object().shape({
    totalCost: yup.string(),
    examList: yup.array().of(
        yup.object().shape({
            orderId: yup.string('Order ID is required'),
            examId: yup.string('Exam ID is required'),
            unit: yup.number().typeError('Unit must be a number').positive('Unit must be a positive number').required('Unit is required'),
            unitPrice: yup.number().typeError('Unit Price must be a number').positive('Unit Price must be a positive number').required('Unit Price is required'),
            // cost: yup.number().required('Cost is required').positive('Cost must be a positive number'),
        })
    ),
});

const BillingInformation = ({ orders, onGoBack, itemData }) => {
    const dispatch = useDispatch();
    let { getIdRes, getPatientOrders } = useSelector(state => state.orders);
    let { postInvoice, putInvoice } = useSelector(state => state.invoice);
    const [patientOrders, setPatientOrders] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [orderModal, setOrderModal] = useState(false);
    const [quantityValues, setQuantityValues] = useState([]);
    const [unitPriceValues, setUnitPriceValues] = useState([]);
    const [notes, setNotes] = useState("")

    const {register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        dispatch(setMainHeading("Billing & Invoicing"))
        if (getIdRes?.success) {
            const list = getIdRes?.data?.reduce((acc, order) => [
                ...acc,
                ...(order.radiologyId?.examList?.flatMap(group => group.list.map(item => ({
                    ...item,
                    orderId: order.orderId,
                    createdAt: order.createdAt
                }))) || [])
            ], []);

            setSelectedValue({
                patient: getIdRes?.data[0]?.patientId,
                examList: list,
            })
            dispatch(resetGetId())
        } else if (getPatientOrders?.success) {
            setOrderModal(true)
            setPatientOrders(getPatientOrders?.data);
            dispatch(resetgetPatientOrders())
        }
    }, [dispatch, getIdRes, getPatientOrders])


    useEffect(() => {
        const orderIds = orders.map(order => order.orderId).join(', ');
        dispatch(getIdRequest(orderIds));
    }, [dispatch, orders]);

    useEffect(() => {
        if (itemData[0]) {
            const initialQuantities = itemData[0]?.invoiceDetail.map((exam) => exam.unit) || [];
            const initialUnitPrices = itemData[0]?.invoiceDetail.map((exam) => exam.unitPrice) || [];

            setQuantityValues(initialQuantities);
            setUnitPriceValues(initialUnitPrices);
            setNotes(itemData[0]?.notes)
        }
    }, [itemData]);

    useEffect(() => {
        if (postInvoice?.success || putInvoice?.success) {
            onGoBack();
            dispatch(resetPut())
            dispatch(resetPost())
        }
    }, [postInvoice?.success, putInvoice?.success, onGoBack]);

    const handleQuantityChange = (e, index) => {
        const newQuantityValues = [...quantityValues];
        newQuantityValues[index] = e.target.value;
        setQuantityValues(newQuantityValues);
    };

    const handleUnitPriceChange = (e, index) => {
        const newUnitPriceValues = [...unitPriceValues];
        newUnitPriceValues[index] = e.target.value;
        setUnitPriceValues(newUnitPriceValues);
    };

    const calculateCost = (quantity, unitPrice) => {
        const parsedQuantity = parseFloat(quantity) || 0;
        const parsedUnitPrice = parseFloat(unitPrice) || 0;
        return parsedQuantity * parsedUnitPrice;
    };


    const calculateTotalCost = () => {
        return quantityValues.reduce((total, quantity, index) => {
            const cost = calculateCost(quantity, unitPriceValues[index]);
            return total + cost;
        }, 0).toString();
    };

    const onchangeNotes = (value) => {
        setNotes(value)
    }
    const onSubmit = (data) => {
        if (itemData[0]) {
            const { examList } = itemData[0].invoiceDetail
            const updatedInvoiceDetail = itemData[0].invoiceDetail.map((exam, index) => {
                return {
                    _id: exam._id,
                    orderDate: exam.orderDate,
                    orderId: exam.orderId,
                    examName: exam.examId,
                    unit: quantityValues[index] || '0',
                    unitPrice: unitPriceValues[index] || '0',
                    cost: calculateCost(quantityValues[index], unitPriceValues[index]).toString(),
                };
            });

            const requestUpdateData = {
                patientId: itemData[0]?.patientId?._id,
                invoiceDetail: updatedInvoiceDetail,
                totalCost: calculateTotalCost(),
                invoiceId: itemData[0].invoiceId,
                notes: notes
            };
            dispatch(putRequest(requestUpdateData));
        }
        else {
            const { examList } = selectedValue;

            const invoiceDetail = examList.map((exam, index) => {
                return {
                    orderDate: exam.createdAt,
                    orderId: exam.orderId,
                    examId: exam._id,
                    unit: quantityValues[index] || '0',
                    unitPrice: unitPriceValues[index] || '0',
                    cost: calculateCost(quantityValues[index], unitPriceValues[index]).toString(),
                };
            });

            const requestData = {
                patientId: selectedValue?.patient?._id,
                invoiceDetail,
                totalCost: selectedValue?.examList.reduce((total, exam, index) => {
                    const cost = calculateCost(quantityValues[index], unitPriceValues[index]);
                    return total + cost;
                }, 0).toString(),
                notes: notes
            };
            dispatch(postRequest(requestData));
        }
    };

    return (
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <Container maxWidth={false} sx={{ px: { xs: 3, lg: 4, xl: 6 } }} className="billing-information-container">
                <Paper sx={{ p: { xs: 2, md: 3, xl: 4 }, width: '100%', borderRadius: '12px', mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, flexWrap: { xs: 'wrap', sm: 'nowrap' }, gap: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {itemData[0] ? "Update Invoice" : "Create Invoice"}
                        </Typography>
                        <Button 
                            onClick={onGoBack} 
                            variant="outlined" 
                            color="primary"
                            size="medium"
                        >
                            Back
                        </Button>
                    </Box>

                    <Paper sx={{ p: { xs: 2, md: 3 }, mb: 3, borderRadius: '8px' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: { xs: 2, md: 3 } }}>Patient Information</Typography>
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Typography color="text.secondary" variant="body2">First Name</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{itemData[0] ? itemData[0]?.patientId?.fName : selectedValue?.patient?.fName}</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Typography color="text.secondary" variant="body2">Last Name</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{itemData[0] ? itemData[0]?.patientId?.lName : selectedValue?.patient?.lName}</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Typography color="text.secondary" variant="body2">Email Address</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{itemData[0] ? itemData[0]?.patientId?.email : selectedValue?.patient?.email}</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Typography color="text.secondary" variant="body2">Gender</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{itemData[0] ? itemData[0]?.patientId?.gender : selectedValue?.patient?.gender}</Typography>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Typography color="text.secondary" variant="body2">D.O.B</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{itemData[0] ? itemData[0]?.patientId?.dob : selectedValue?.patient?.dob}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ borderRadius: '8px', overflow: 'hidden', mb: 3 }}>
                        <TableContainer sx={{ 
                            maxHeight: { md: 'calc(100vh - 450px)' },
                            overflow: 'auto'
                        }}>
                            <Table 
                                sx={{ 
                                    width: "100%",
                                    tableLayout: { md: 'fixed' }
                                }} 
                                aria-label="customized table" 
                                className='commonTable'
                            >
                                <TableHead>
                                    <TableRow>
                                        {tableHeading.map((elem) => (
                                            <StyledTableCell 
                                                key={elem}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    px: 2
                                                }}
                                            >
                                                {elem}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {selectedValue ? (
                                    <TableBody>
                                        {selectedValue?.examList ? (
                                            selectedValue?.examList?.map((exam, index) => (
                                                <StyledTableRow key={exam._id}>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>{index + 1}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        {moment(exam.createdAt).format("MM-DD-YYYY")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>{exam.orderId}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2, maxWidth: { lg: '250px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{exam.name}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <Input
                                                            value={quantityValues[index]}
                                                            {...register(`examList[${index}].unit`)}
                                                            onChange={(e) => handleQuantityChange(e, index)}
                                                            error={!!errors.examList?.[index]?.unit}
                                                            size="small"
                                                            sx={{ width: '80px' }}
                                                        />
                                                        {errors.examList?.[index]?.unit && (
                                                            <FormHelperText error>{errors.examList[index].unit.message}</FormHelperText>
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <Input
                                                            value={unitPriceValues[index]}
                                                            {...register(`examList[${index}].unitPrice`)}
                                                            onChange={(e) => handleUnitPriceChange(e, index)}
                                                            error={!!errors.examList?.[index]?.unitPrice}
                                                            size="small"
                                                            sx={{ width: '80px' }}
                                                        />
                                                        {errors.examList?.[index]?.unitPrice && (
                                                            <FormHelperText error>{errors.examList[index].unitPrice.message}</FormHelperText>
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <TextField
                                                            disabled
                                                            value={calculateCost(quantityValues[index], unitPriceValues[index])}
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{ width: '100px' }}
                                                        />  
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={tableHeading.length} align="center">
                                                    <Typography>There is no data to show</Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={tableHeading.length - 1} align="right" sx={{ px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Total:</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left" sx={{ px: 2 }}>
                                                <TextField
                                                    disabled
                                                    value={selectedValue?.examList.reduce((total, exam, index) => {
                                                        const cost = calculateCost(quantityValues[index], unitPriceValues[index]);
                                                        return total + cost;
                                                    }, 0)}
                                                    size="small"
                                                    variant="outlined"
                                                    sx={{ width: '100px' }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                ) : null}

                                {itemData[0] ? (
                                    <TableBody>
                                        {itemData[0] ? (
                                            itemData[0]?.invoiceDetail?.map((exam, index) => (
                                                <StyledTableRow key={exam._id}>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>{index + 1}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        {moment(exam.orderDate).format("MM-DD-YYYY")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>{exam.orderId}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2, maxWidth: { lg: '250px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{exam.examId}</StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <Input
                                                            defaultValue={exam.unit}
                                                            {...register(`examList[${index}].unit`)}
                                                            onChange={(e) => handleQuantityChange(e, index)}
                                                            error={!!errors.examList?.[index]?.unit}
                                                            size="small"
                                                            sx={{ width: '80px' }}
                                                        />
                                                        {errors.examList?.[index]?.unit && (
                                                            <FormHelperText error>{errors.examList[index].unit.message}</FormHelperText>
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <Input
                                                            defaultValue={exam.unitPrice}
                                                            {...register(`examList[${index}].unitPrice`)}
                                                            onChange={(e) => handleUnitPriceChange(e, index)}
                                                            error={!!errors.examList?.[index]?.unitPrice}
                                                            size="small"
                                                            sx={{ width: '80px' }}
                                                        />
                                                        {errors.examList?.[index]?.unitPrice && (
                                                            <FormHelperText error>{errors.examList[index].unitPrice.message}</FormHelperText>
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" sx={{ px: 2 }}>
                                                        <TextField
                                                            defaultValue={exam.cost}
                                                            disabled
                                                            value={calculateCost(quantityValues[index], unitPriceValues[index])}
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{ width: '100px' }}
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={tableHeading.length} align="center">
                                                    <Typography>There is no data to show</Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={tableHeading.length - 1} align="right" sx={{ px: 2 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>Total:</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left" sx={{ px: 2 }}>
                                                <TextField
                                                    disabled
                                                    defaultValue={itemData ? itemData[0]?.totalCost : calculateTotalCost()}
                                                    value={calculateTotalCost()}
                                                    size="small"
                                                    variant="outlined"
                                                    sx={{ width: '100px' }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                ) : null}
                            </Table>
                        </TableContainer>
                    </Paper>

                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 500, mb: 1 }}>Notes</Typography>
                            <TextField 
                                value={notes} 
                                onChange={(e) => onchangeNotes(e.target.value)} 
                                fullWidth 
                                multiline 
                                rows={4} 
                                size='small'
                                placeholder="Add any additional notes here"
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: { xs: 2, md: 3 }, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                type='submit' 
                                variant="contained" 
                                color="primary"
                                size="medium"
                            >
                                {itemData[0] ? "Update Invoice" : "Generate Invoice"}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default BillingInformation;
