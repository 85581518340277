import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
    FormControl, Grid, Card, Box, Typography, Stepper, Step, StepLabel, FormLabel, StepContent, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableBody, Autocomplete, TextField, Container, Paper, Divider, Chip, TableContainer
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getIdRequest, getRequest, getPatientOrdersRequest, resetgetPatientOrders, resetGetId } from '../../../redux/modules/orders/slice';
import moment from 'moment';
import AppointmentCompleted from './appointmentCompleted';
import { setMainHeading } from '../../../redux/modules/mainHeading/slice';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '16px',
        fontSize: '0.9rem',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '12px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        transition: 'background-color 0.2s ease',
    },
}));

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const ProcedureTracking = () => {
    const dispatch = useDispatch();
    let { getRes, getIdRes, getPatientOrders } = useSelector(state => state.orders);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [patientOrders, setPatientOrders] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [orderModal, setOrderModal] = useState(false);

    const {currentBranch} = useSelector(state => state.branch);
    useEffect(()=>{
        if(currentBranch && currentBranch?.id){
            setSelectedValue(null)
            setSelectedOrder(null);
        }
    }, [currentBranch?.id])

    useEffect(() => {
        dispatch(setMainHeading("Procedure Tracking"))
        if (getIdRes?.success) {
            const list = getIdRes?.data[0]?.radiologyId?.examList?.map((item) => item.list).flat();
            let activeStep = 0;
            const currentTimestamp = moment();
            const appoinmentTime = moment(getIdRes?.data[0]?.radiologyId?.appoinmentId?.startTime);
            if (getIdRes?.data[0]?.appoinmentCompleteStaffId && getIdRes?.data[0]?.status === "Appointment Complete") (
                activeStep = 2
            )
            else if (currentTimestamp.isAfter(appoinmentTime)) {
                activeStep = 1
            }
            setSelectedValue({
                orderId: getIdRes?.data[0]?.orderId,
                status: getIdRes?.data[0]?.status,
                appoinmentCompleteStaffId: getIdRes?.data[0]?.appoinmentCompleteStaffId,
                appoinmentCompleteDescription: getIdRes?.data[0]?.appoinmentCompleteDescription,
                appoinment: getIdRes?.data[0]?.radiologyId?.appoinmentId,
                patient: getIdRes?.data[0]?.patientId,
                examList: list,
                activeStep,
            })
            dispatch(resetGetId())
        } else if (getPatientOrders?.success) {
            setOrderModal(true)
            setPatientOrders(getPatientOrders?.data);
            dispatch(resetgetPatientOrders())
        }
    }, [dispatch, getIdRes, getPatientOrders])


    const handleSelect = (selected) => {
        if (selected) {
            setSelectedOrder(selected);
            setSelectedPatient(null)
            dispatch(getIdRequest(selected.orderId))
        }
    };

    const handlepatientSelect = (selected) => {
        if (selected) {
            setSelectedValue(null)
            setSelectedOrder(null);
            setSelectedPatient(selected)
            dispatch(getPatientOrdersRequest(selected?._id))
        }
    };

    const handleInputChange = async (value, elm) => {
        dispatch(getRequest({ value: value, elm: elm }));
    }


    return (
        <Container maxWidth={false} sx={{ py: 3, px: { xs: 2, md: 3, xl: 4 } }}>
            <Paper elevation={2} sx={{ p: { xs: 2, md: 3 }, borderRadius: '12px', mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>Search Procedure</Typography>
                <Grid container spacing={3}>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{ mb: 1, fontWeight: 500 }}>Order ID</FormLabel>
                            <Autocomplete
                                sx={{ 
                                    backgroundColor: "#F5F7F9", 
                                    borderRadius: "8px", 
                                    '& .MuiOutlinedInput-root': {
                                        padding: '4px 12px'
                                    }
                                }}
                                value={selectedOrder}
                                options={getRes?.orders || []}
                                getOptionValue={(option) => option._id}
                                getOptionLabel={(option) => option?.orderId || ''}
                                onInputChange={(event, value) => handleInputChange(value, "order")}
                                onChange={(event, newValue) => handleSelect(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select an Order ID" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{ mb: 1, fontWeight: 500 }}>Patient</FormLabel>
                            <Autocomplete
                                sx={{ 
                                    backgroundColor: "#F5F7F9", 
                                    borderRadius: "8px",
                                    '& .MuiOutlinedInput-root': {
                                        padding: '4px 12px'
                                    }
                                }}
                                value={selectedPatient}
                                autoFocus={false}
                                options={getRes?.patients || []}
                                getOptionValue={(option) => option._id}
                                getOptionLabel={(option) => `${option?.fName} ${option?.lName},  ${capitalizeFirstLetter(option?.gender)},  ${option?.dob},  ${option?.email}`}
                                onInputChange={(event, value) => handleInputChange(value, "patient")}
                                onChange={(event, newValue) => handlepatientSelect(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Select a Patient" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
                
            {selectedValue && (
                <>
                    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 }, borderRadius: '12px', mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>Patient Information</Typography>
                            <Chip 
                                label={selectedValue?.status || "Processing"} 
                                color={selectedValue?.status === "Appointment Complete" ? "success" : "primary"}
                                sx={{ fontWeight: 500 }}
                            />
                        </Box>
                        
                        <Divider sx={{ mb: 3 }} />
                        
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>First Name</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.patient?.fName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Last Name</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.patient?.lName}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Email Address</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.patient?.email}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Gender</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{capitalizeFirstLetter(selectedValue?.patient?.gender)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>D.O.B</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.patient?.dob}</Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Exams</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selectedValue?.examList?.map((item, index) => (
                                            <Chip 
                                                key={item?._id || index} 
                                                label={item?.name} 
                                                size="small" 
                                                variant="outlined" 
                                                color="primary"
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    
                    <Paper elevation={2} sx={{ p: { xs: 2, md: 3 }, borderRadius: '12px' }}>
                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>Procedure Timeline</Typography>
                        
                        <Stepper activeStep={selectedValue?.activeStep} orientation="vertical" sx={{ px: 2 }}>
                            <Step>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                                        Appointment Made
                                    </Typography>
                                    <Paper variant="outlined" sx={{ p: 2, borderRadius: 2, bgcolor: 'background.default' }}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Appointment Type</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.appoinment?.appoinmentCategory}</Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Appointment Start Time</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{moment(selectedValue?.appoinment?.startTime).format("MM-DD-YYYY hh:mm A")}</Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Appointment Duration</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.appoinment?.appoinmentDuration} Minutes</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </StepLabel>
                            </Step>
                            
                            <Step>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                                        Appointment Completed
                                    </Typography>
                                    {selectedValue?.appoinmentCompleteStaffId?._id && (
                                        <Paper variant="outlined" sx={{ p: 2, borderRadius: 2, bgcolor: 'background.default' }}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>First Name</Typography>
                                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.appoinmentCompleteStaffId?.firstName}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>Last Name</Typography>
                                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.appoinmentCompleteStaffId?.lastName || "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>Examination Comment</Typography>
                                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedValue?.appoinmentCompleteDescription || "-"}</Typography>
                                                </Grid>
                                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>Status</Typography>
                                                    <Chip 
                                                        label={selectedValue?.status || "-"} 
                                                        color={selectedValue?.status === "Appointment Complete" ? "success" : "primary"}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )}
                                </StepLabel>
                                {!selectedValue?.appoinmentCompleteStaffId?._id && (
                                    <StepContent>
                                        <Box sx={{ py: 2 }}>
                                            <AppointmentCompleted orderId={selectedValue?.orderId} />
                                        </Box>
                                    </StepContent>
                                )}
                            </Step>
                            
                            <Step>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                        Modality
                                    </Typography>
                                </StepLabel>
                            </Step>
                            
                            <Step>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                        Image Printed
                                    </Typography>
                                </StepLabel>
                            </Step>
                            
                            <Step>
                                <StepLabel>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                        Report Link
                                    </Typography>
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Paper>
                </>
            )}

            <Dialog 
                maxWidth="lg" 
                fullWidth
                open={orderModal} 
                onClose={() => { setOrderModal(false) }}
                PaperProps={{
                    sx: { borderRadius: '12px' }
                }}
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Select Patient Order</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer sx={{ 
                        maxHeight: 'calc(100vh - 300px)',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#bdbdbd',
                            borderRadius: '4px',
                        }
                    }}>
                        <Table stickyHeader aria-label="Order selection table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Appointment Date & Time</StyledTableCell>
                                    <StyledTableCell>Order ID</StyledTableCell>
                                    <StyledTableCell>Patient Name</StyledTableCell>
                                    <StyledTableCell>Exams</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {patientOrders?.length > 0 ?
                                    patientOrders?.map(elem => (
                                        <StyledTableRow key={elem._id}>
                                            <StyledTableCell align="left">
                                                {moment(elem?.radiologyId?.appoinmentId?.startTime).format('MM-DD-YYYY HH:mm A')}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {elem?.orderId}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {selectedPatient?.fName} {selectedPatient?.lName}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Box sx={{ maxWidth: '300px', maxHeight: '100px', overflow: 'auto' }}>
                                                    {elem?.radiologyId?.examList?.map((item) => item.list.map((listItem) => listItem?.name)).flat().join(", ")}
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {elem?.radiologyId?.appoinmentId?.appoinmentCategory}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button 
                                                    onClick={() => {
                                                        const list = elem?.radiologyId?.examList?.map((item) => item.list).flat();
                                                        let activeStep = 0;
                                                        const currentTimestamp = moment();
                                                        const appoinmentTime = moment(elem?.radiologyId?.appoinmentId?.startTime);

                                                        if (elem?.appoinmentCompleteStaffId && elem?.status === "Appointment Complete") (
                                                            activeStep = 2
                                                        )
                                                        else if (currentTimestamp.isAfter(appoinmentTime)) {
                                                            activeStep = 1
                                                        }
                                                        setSelectedValue({
                                                            orderId: elem?.orderId,
                                                            status: elem?.status,
                                                            appoinmentCompleteStaffId: elem?.appoinmentCompleteStaffId,
                                                            appoinmentCompleteDescription: elem?.appoinmentCompleteDescription,
                                                            appoinment: elem?.radiologyId?.appoinmentId,
                                                            patient: selectedPatient,
                                                            examList: list,
                                                            activeStep
                                                        })
                                                        setOrderModal(false)
                                                    }} 
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    Select
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                    :
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={6} align="center">
                                            <Typography sx={{ py: 2 }}>There is no data to show</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button 
                        onClick={() => { setOrderModal(false) }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ProcedureTracking;
