import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, Typography, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Pagination, Stack, Box, TextField, Container, Grid, useTheme, useMediaQuery } from '@mui/material';
import { deleteRequest, getRequest, resetDelete, resetIdGet } from '../../../redux/modules/radiology/slice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { DeleteConfirmationModal, ViewConfirmationModal } from '../../../utils/modal';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import SearchInput from '../../../utils/searchInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DicomViewer from '../image-report/DwvComponent';
import { USER_ROLE_DEFAULT, USER_ROLE } from '../../../constants/Constant';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '16px 12px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1rem',
      padding: '20px 16px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '12px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.9rem',
      padding: '16px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    transition: 'background-color 0.2s ease',
  },
}));

const tableHeading = ["Appointment Date & Time", "Order Id", "Patient Name", "Exams", "Status", "Category", "Action"];

let deleteItemId;
let limit = 10;
let pageCount = 0;

export default function RadiologyList({ setItemData, toggleList, setPreviewRadiology }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  
  let { getRes, deleteRes } = useSelector(state => state.radiology);
  let data = useSelector(state => state.orders);
  let { currentBranch } = useSelector(state => state.branch);
  let { userData } = useSelector(state => state.auth);
  let { getRes: profileData } = useSelector(state => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [previewReprt, setPreviewReport] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [appointmentCompleteDescription, setAppointmentCompleteDescription] = useState("");

  // Adjust page size for large screens
  useEffect(() => {
    if (isExtraLargeScreen) {
      limit = 15;
    } else {
      limit = 10;
    }
  }, [isExtraLargeScreen]);

  const filteredTableHeadings = userData.role !== USER_ROLE_DEFAULT.PATIENT 
  ? tableHeading 
  : tableHeading.filter(column => 
      column !== 'Patient Name' && column !== 'Status' && column !== 'Category'
    );

  const filteredElem = isFilterActive && filteredData.length > 0 
    ? filteredData 
    : userData.role !== USER_ROLE_DEFAULT.PATIENT
    ? getRes?.data
      : getRes && getRes?.data?.filter((data)=>data?.orderId?.status === "Appointment Complete");

  useEffect(() => {
    if (!currentBranch) { return }
    dispatch(getRequest({ page, limit, searchQuery }))
  }, [dispatch, currentBranch, page, searchQuery, limit]);

  useEffect(() => {
    if (!deleteRes) { return }
    if (deleteRes?.success) {
      dispatch(getRequest({ page, limit }))
    }
    toast(deleteRes?.message);
    dispatch(resetDelete());
  }, [dispatch, deleteRes, page, limit]);

  useEffect(() => {
    if (startDate && endDate && getRes?.data) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      // Set end date to end of day for inclusive filtering
      endDateObj.setHours(23, 59, 59, 999);

      // Filter data by date range
      const filtered = getRes.data.filter(elem => {
        const appointmentDate = new Date(elem?.appoinmentId?.startTime);
        return appointmentDate >= startDateObj && appointmentDate <= endDateObj;
      });
      
      setFilteredData(filtered);
      setIsFilterActive(true);
      
      // Reset to first page when filter changes
      setPage(1);
    }
  }, [startDate, endDate, getRes?.data]);

  if (getRes?.dataCount && getRes?.dataCount > limit) {
    pageCount = getRes?.dataCount / limit;
    if (pageCount % 2 !== 0) {
      pageCount = Math.ceil(pageCount)
    }
  }

  const handleClearFilters = () => {
    setStartDate("");
    setEndDate("");
    setIsFilterActive(false);
    setFilteredData([]);
  };

  const renderingRow = (elem) => {
    let ruturnElem = (
      <StyledTableRow key={elem._id}>
        <StyledTableCell align="left">
          <Typography variant="body2">
            {moment(elem?.appoinmentId?.startTime).format("MM-DD-YYYY HH:mm A")}{" "}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{elem?.orderId?.orderId} </Typography>
        </StyledTableCell>
        {userData.role !== USER_ROLE_DEFAULT.PATIENT && 
        <StyledTableCell align="left">
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {elem?.patientId?.fName} {elem?.patientId?.lName}
          </Typography>
        </StyledTableCell>
        }
        <StyledTableCell align="left">
          <Typography variant="body2" sx={{ 
            maxHeight: { xs: '60px', md: '100px' },
            overflow: 'auto',
            maxWidth: { md: '300px', lg: '400px', xl: '600px' }
          }}>
            {elem.examList
              .map((item) => item.list.map((listItem) => listItem?.name))
              .flat()
              .join(", ")}
          </Typography>
        </StyledTableCell>
        {userData.role !== USER_ROLE_DEFAULT.PATIENT &&
        <StyledTableCell align="left">
          <Typography
            className={`status-${
              elem?.orderId?.status === "Appointment Miss"
                ? "miss"
                : elem?.orderId?.status === "Appointment Complete"
                ? "complete"
                : elem?.orderId?.status === "Requested"
                ? "requested"
                : ""
            } status-chips`}
            variant="body2"
            sx={{ fontWeight: 500 }}
          >
            {elem?.orderId?.status && <span className="status-dot"></span>}{" "}
            {elem?.orderId?.status}
          </Typography>
        </StyledTableCell>
        }
        {userData.role !== USER_ROLE_DEFAULT.PATIENT &&
        <StyledTableCell align="left">
          <Typography variant="body2">{elem?.appoinmentId?.appoinmentCategory}</Typography>
        </StyledTableCell>
        }
        <StyledTableCell align="left">
          <Box sx={{ 
            display: 'flex', 
            gap: { xs: 0.5, md: 1 },
            flexDirection: { xs: 'column', sm: 'row' }
          }}>
            <IconButton
              color="primary.dark"
              size={isSmallScreen ? 'small' : 'medium'}
              onClick={() => {
                setItemData({ id: elem._id });
                if(userData.role !== USER_ROLE_DEFAULT.PATIENT){
                  setPreviewRadiology(true);
                }else{
                  setAppointmentCompleteDescription(elem.orderId.appoinmentCompleteDescription);
                  setIsViewModalOpen(true)
                }
              }}
              sx={{ p: { xs: 0.5, md: 1 } }}
            >
              <VisibilityIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
            </IconButton>
            {userData.role !== USER_ROLE_DEFAULT.PATIENT ?<IconButton
              color="primary"
              size={isSmallScreen ? 'small' : 'medium'}
              onClick={() => {
                setItemData({ id: elem._id ,gender:elem?.patientId?.gender,});
                toggleList();
              }}
              sx={{ p: { xs: 0.5, md: 1 } }}
            >
              <EditIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
            </IconButton>:null}
            {userData.role===USER_ROLE_DEFAULT.ADMIN ?(
            <IconButton
              color="error"
              size={isSmallScreen ? 'small' : 'medium'}
              onClick={() => {
                deleteItemId = elem._id;
                setIsModalOpen(true);
              }}
              sx={{ p: { xs: 0.5, md: 1 } }}
            >
              <DeleteIcon fontSize={isSmallScreen ? 'small' : 'medium'} />
            </IconButton>):<></>}
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    );
    if (elem.isReffered) {
      if (elem.orderId !== null) {
        return ruturnElem;
      }
      return null;
    } else {
      return ruturnElem;
    }
  };

  return (
    <>
      {!previewReprt && (
        <Container maxWidth={false} sx={{ 
          px: { xs: 2, md: 3, xl: 5 }, 
          width: '100%',
          maxWidth: { xl: '100%' }
        }}>
          <Paper 
            elevation={2} 
            sx={{ 
              p: { xs: 2, md: 3, xl: 4 }, 
              borderRadius: '12px',
              mb: 4,
              overflow: 'hidden',
              width: '100%'
            }}
          >
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} lg={9}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: { xs: 'column', md: 'row' }, 
                  gap: 2 
                }}>
                  <Box sx={{ width: { xs: '100%', md: '250px', lg: '300px' } }}>
                    <SearchInput 
                      setSearchQuery={(searchValue) => { 
                        setSearchQuery(searchValue); 
                        setPage(1); 
                      }} 
                    />
                  </Box>
                  
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2, 
                      p: { xs: 1.5, md: 2 },
                      bgcolor: '#f5f5f5', 
                      borderRadius: 1,
                      flexWrap: { xs: 'wrap', md: 'nowrap' },
                      width: '100%'
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <CalendarTodayIcon fontSize="small" color="primary" />
                      <Typography variant="body2" sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                        Filter by Date:
                      </Typography>
                    </Box>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2,
                      flexWrap: { xs: 'wrap', md: 'nowrap' },
                      width: { xs: '100%', md: 'auto' },
                      alignItems: 'center'
                    }}>
                      <TextField
                        label="From"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{ 
                          minWidth: { xs: '100%', sm: '150px', md: '140px' },
                          flex: { xs: 1, md: 'unset' }
                        }}
                      />
                      <TextField
                        label="To"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{ 
                          minWidth: { xs: '100%', sm: '150px', md: '140px' },
                          flex: { xs: 1, md: 'unset' }
                        }}
                      />
                      
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton 
                          color="primary" 
                          disabled={!startDate || !endDate}
                          onClick={() => {
                            if (startDate && endDate) {
                              // Filter is applied in useEffect
                            }
                          }}
                          size="small"
                        >
                          <FilterAltIcon />
                        </IconButton>
                        
                        {isFilterActive && (
                          <IconButton 
                            color="default" 
                            onClick={handleClearFilters}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
              
              <Grid item xs={12} lg={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', lg: 'flex-end' } }}>
                {userData.role===USER_ROLE_DEFAULT.ADMIN && (
                  <Button 
                    className='commonBtn' 
                    variant="contained" 
                    onClick={() => { 
                      toggleList(); 
                      setItemData({}); 
                      dispatch(resetIdGet());
                    }} 
                    startIcon={<AddIcon />}
                    sx={{ 
                      minWidth: 'max-content',
                      height: 'fit-content'
                    }}
                  > 
                    Add Order 
                  </Button>
                )}
              </Grid>
            </Grid>
            
            {isFilterActive && (
              <Box sx={{ mb: 3, p: 2, bgcolor: '#f8f9fa', borderRadius: 1, border: '1px solid #e0e0e0' }}>
                <Typography variant={isExtraLargeScreen ? 'body1' : 'body2'} sx={{ color: 'text.secondary' }}>
                  <strong>{filteredData.length}</strong> results found for appointments between <strong>{moment(startDate).format("MM/DD/YYYY")}</strong> and <strong>{moment(endDate).format("MM/DD/YYYY")}</strong>
                  {filteredData.length === 0 && (
                    <Typography component="span" sx={{ color: 'error.main', ml: 1, fontWeight: 500 }}>
                      No appointments found in the selected date range
                    </Typography>
                  )}
                </Typography>
              </Box>
            )}
            
            <TableContainer sx={{ 
              borderRadius: '8px',
              overflow: 'auto',
              maxHeight: { lg: 'calc(100vh - 300px)', xl: 'calc(100vh - 340px)' },
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#bdbdbd',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}>
              <Table 
                stickyHeader 
                sx={{ 
                  minWidth: 700,
                  width: '100%',
                  tableLayout: isExtraLargeScreen ? 'fixed' : 'auto',
                }} 
                aria-label="Order Management Table"
              >
              <TableHead>
                <TableRow>
                    {filteredTableHeadings.map(elem => (
                      <StyledTableCell key={elem}>
                        {elem}
                      </StyledTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredElem?.length > 0 ?
                    filteredElem?.map(elem => renderingRow(elem))
                  :
                  <StyledTableRow>
                      <StyledTableCell colSpan={filteredTableHeadings?.length} align="center">
                        <Typography variant="body1" sx={{ py: 3 }}>There is no data to show</Typography>
                      </StyledTableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

            {(getRes?.dataCount > limit) && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 3,
                '& .MuiPagination-ul': {
                  '& .MuiPaginationItem-root': {
                    fontSize: isExtraLargeScreen ? '1rem' : '0.875rem',
                  }
                }
              }}>
                <Pagination 
                  count={pageCount} 
                  page={page} 
                  onChange={(event, value) => { setPage(value); }}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  size={isExtraLargeScreen ? "large" : "medium"}
                />
              </Box>
            )}
          </Paper>
          
          <DeleteConfirmationModal 
            open={isModalOpen} 
            onClose={() => setIsModalOpen(false)} 
            onConfirm={() => { 
              dispatch(deleteRequest(deleteItemId)); 
              setIsModalOpen(false);
            }} 
          />
          
          <ViewConfirmationModal  
            description={appointmentCompleteDescription} 
            open={isViewModalOpen} 
            onClose={() => setIsViewModalOpen(false)} 
            onConfirm={() => { setIsViewModalOpen(false); }} 
          />
        </Container>
      )}
      {previewReprt && (
        <DicomViewer patient={selectedPatient} onClose={() => setPreviewReport(false)} />
      )}
    </>
  );
}