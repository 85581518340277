import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, Typography, TableContainer, TableHead, TableRow, Paper, IconButton, Pagination, Box, Chip, Tooltip, CircularProgress } from '@mui/material';
import { getPatientProfileRequest, deletePatientProfileRequest, resetDelete, resetPatientData } from '../../../redux/modules/admin/patientProfile/slice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { DeleteConfirmationModal } from '../../../utils/modal';
import PatientTabHeader from './patientTabHeader';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '12px 16px',
        fontSize: '0.95rem',
        fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '12px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        transition: 'background-color 0.2s ease',
    },
}));

const EllipsisText = ({ children }) => (
    <Typography 
        noWrap 
        sx={{ 
            maxWidth: { xs: '120px', sm: '150px', md: '100%' },
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }}
    >
        {children}
    </Typography>
);

const tableHeading = ["Full Name", "Patient ID", "Email Address", "Phone Number", "Date of Birth", "Gender", "Action"];

let deleteItemId;
export default function PatientList({ setItemData, toggleList }) {
    const [searchText, setSearchText] = useState("");
    const [searchType, setSearchType] = useState("name");
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    let { getInfoRes, deleteRes, loading: reduxLoading } = useSelector(state => state.patientProfile);
    let { currentBranch } = useSelector(state => state.branch);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    let cardLimit = 10;
    let pageCount = 0;

    // Calculate page count from API response
    if (getInfoRes?.dataCount && getInfoRes?.dataCount > cardLimit) {
        pageCount = getInfoRes.dataCount / cardLimit;
        if (pageCount % 2 !== 0) {
            pageCount = Math.ceil(pageCount)
        }
    }

    // Initial data load and search handling
    useEffect(() => {
        if (!currentBranch) return;
        
        setLoading(true);
        
        dispatch(getPatientProfileRequest({
            limit: cardLimit, 
            search: searchText,
            searchType: searchType, 
            skip: (page - 1) * cardLimit,
            currentBranch: currentBranch?.id,
        }));
        
        // Only reset patient data on component mount
        return () => {
            dispatch(resetPatientData());
        };
    }, [dispatch, currentBranch, searchText, searchType, page]);

    // Handle deletion response
    useEffect(() => {
        if (!deleteRes) return;
        
        if (deleteRes?.success) {
            dispatch(getPatientProfileRequest({
                limit: cardLimit, 
                search: searchText, 
                searchType: searchType, 
                skip: (page - 1) * cardLimit,
                currentBranch: currentBranch?.id,
            }));
        }
        
        toast(deleteRes?.message);
        dispatch(resetDelete());
    }, [deleteRes]);

    // Update loading state when Redux loading changes
    useEffect(() => {
        if (!reduxLoading) {
            setLoading(false);
        }
    }, [reduxLoading]);

    const handleSearchCallback = (type, text) => {
        // Reset page when search changes
        setPage(1);
        setSearchType(type);
        setSearchText(text);
    };

    const handlePagination = (event, value) => {
        setPage(value);
    };

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    };

    // Get patient data directly from Redux state
    const patientData = getInfoRes?.data || [];

    return (
        <>
            <PatientTabHeader handleSearchCallback={handleSearchCallback} />
            <Paper elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <TableContainer className='tableShadow' sx={{ overflowX: 'auto' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" className='commonTable'>
                    <TableHead>
                        <TableRow>
                                {tableHeading.map(elem => (
                                    <StyledTableCell key={elem}>{elem}</StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {loading ? (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={tableHeading.length} align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
                                            <CircularProgress size={30} thickness={4} />
                                            <Typography sx={{ ml: 2, color: 'text.primary' }}>
                                                Loading results...
                                            </Typography>
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : patientData.length > 0 ? (
                                patientData.map((elem, index) => (
                                    <StyledTableRow key={`patient-${elem._id}-${index}`}>
                                        <StyledTableCell align="left">
                                            <Tooltip title={`${elem.fName} ${elem.lName}`} enterDelay={700}>
                                                <EllipsisText>{`${elem.fName} ${elem.lName}`}</EllipsisText>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <EllipsisText>{elem?.orthancPatientId}</EllipsisText>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Tooltip title={elem?.email} enterDelay={700}>
                                                <EllipsisText>{elem?.email}</EllipsisText>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <EllipsisText>{elem?.contactNumber}</EllipsisText>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <EllipsisText>{moment(elem?.dob).format('DD-MM-YYYY')}</EllipsisText>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Chip 
                                                label={capitalizeFirstLetter(elem?.gender)} 
                                                size="small"
                                                color={elem?.gender === 'male' ? 'primary' : 'default'}
                                                sx={{
                                                    backgroundColor: elem?.gender === 'female' ? '#f48fb1' : undefined,
                                                    color: elem?.gender === 'female' ? '#000' : undefined,
                                                    fontWeight: 500,
                                                    border: '1px solid',
                                                    borderColor: elem?.gender === 'female' ? '#f06292' : undefined
                                                }}
                                            />
                                        </StyledTableCell>
                                    <StyledTableCell align="left">
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Tooltip title="Edit patient">
                                                    <IconButton 
                                                        color="primary" 
                                                        onClick={() => { setItemData(elem); toggleList() }}
                                                        size="small"
                                                        sx={{ 
                                                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                                            '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.15)' }
                                                        }}
                                                    >
                                                        <EditIcon fontSize="small" />
                                            </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete patient">
                                                    <IconButton 
                                                        color="error" 
                                                        onClick={() => { deleteItemId = elem._id; setIsModalOpen(true) }}
                                                        size="small"
                                                        sx={{ 
                                                            backgroundColor: 'rgba(211, 47, 47, 0.08)',
                                                            '&:hover': { backgroundColor: 'rgba(211, 47, 47, 0.15)' }
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                            </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={tableHeading.length} align="center">
                                        <Typography sx={{ py: 3, color: 'text.secondary' }}>
                                            No patient records found{searchText ? ` for "${searchText}"` : ''}
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper>
            {pageCount > 0 && (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        mt: 4, 
                        mb: 2 
                    }}
                >
                    <Pagination 
                        count={pageCount} 
                        color="primary" 
                        shape="rounded" 
                        page={page} 
                        onChange={handlePagination} 
                        showFirstButton 
                        showLastButton
                        sx={{
                            '& .MuiPaginationItem-root': {
                                fontWeight: 500
                            }
                        }}
                    />
            </Box>
            )}
            <DeleteConfirmationModal 
                open={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                onConfirm={() => { 
                    dispatch(deletePatientProfileRequest(deleteItemId)); 
                    setIsModalOpen(false) 
                }} 
            />
        </>
    );
}