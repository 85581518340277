import DashboardHeader from '../../../component/dashboard/DashboardHeader';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { USER_ROLE_DEFAULT } from '../../../constants/Constant';

const settingsList = [
  {
    navIcon: <InboxIcon />,
    navText: "Edit Profile",
    navLink: "juniorRadiologist/edit-profile",
  },
  {
    navIcon: <InboxIcon />,
    navText: "Settings",
    navLink: "juniorRadiologist/settings",
  }
];

const Header = ({ drawerWidth, handleDrawerToggle }) => {
  return (
    <DashboardHeader 
      drawerWidth={drawerWidth}
      handleDrawerToggle={handleDrawerToggle}
      settingsList={settingsList}
      userRole={USER_ROLE_DEFAULT.JUNIOR_RADIOLOGIST}
    />
  );
};

export default Header;