import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Tooltip,
  alpha,
  useTheme,
  Chip,
  FormHelperText,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { getRequest } from '../../../redux/modules/branch/slice';
import { updateBranchModalities, getBranchModalities } from '../../../redux/modules/settings/slice';
import { useNavigate } from 'react-router-dom';
import { setMainHeading } from '../../../redux/modules/mainHeading/slice';
import { styled } from '@mui/material/styles';

const MODALITY_TYPES = [
  { value: 'CT', label: 'CT Scanner' },
  { value: 'MRI', label: 'MRI' },
  { value: 'XR', label: 'X-Ray' },
  { value: 'US', label: 'Ultrasound' },
  { value: 'MG', label: 'Mammography' },
  { value: 'RF', label: 'Radiography/Fluoroscopy' },
  { value: 'NM', label: 'Nuclear Medicine' },
  { value: 'PET', label: 'PET Scanner' },
];

const DEVICE_STATUS = [
  { value: 'operational', label: 'Operational', color: 'success' },
  { value: 'maintenance', label: 'Under Maintenance', color: 'warning' },
  { value: 'offline', label: 'Offline', color: 'error' },
  { value: 'scheduled', label: 'Scheduled Maintenance', color: 'info' },
];

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

const StatusChip = styled(Chip)(({ theme, devicestatus }) => ({
  fontWeight: 'bold',
  fontSize: '0.8rem',
}));

const LoadingSpinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
    <CircularProgress />
  </Box>
);

const ModalityEquipmentSettings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  
  const [selectedBranch, setSelectedBranch] = useState('');
  const [equipment, setEquipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentDevice, setCurrentDevice] = useState({
    id: '',
    name: '',
    type: '',
    serialNumber: '',
    manufacturer: '',
    status: 'operational',
    installDate: '',
    calibrationDate: '',
    nextMaintenanceDate: '',
    notes: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const { getRes, currentBranch } = useSelector(state => state.branch || { getRes: null, currentBranch: null });
  
  const branchModalities = useSelector(state => 
    state.settings?.modalities?.data || null
  );
  
  const modalitiesLoading = useSelector(state => 
    state.settings?.modalities?.loading || false
  );
  
  useEffect(() => {
    dispatch(getRequest());
  }, [dispatch]);
  
  useEffect(() => {
    if (currentBranch?.id) {
      setSelectedBranch(currentBranch.id);
      dispatch(getBranchModalities(currentBranch.id));
    }
  }, [currentBranch, dispatch]);
  
  useEffect(() => {
    if (branchModalities) {
      if (branchModalities.modalities) {
        setEquipment(branchModalities.modalities);
      } else {
        setEquipment(Array.isArray(branchModalities) ? branchModalities : []);
      }
    } else {
      setEquipment([]);
    }
  }, [branchModalities]);
  
  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };
  
  const openAddDialog = () => {
    setCurrentDevice({
      id: '',
      name: '',
      type: '',
      serialNumber: '',
      manufacturer: '',
      status: 'operational',
      installDate: '',
      calibrationDate: '',
      nextMaintenanceDate: '',
      notes: ''
    });
    setFormErrors({});
    setDialogOpen(true);
  };
  
  const openEditDialog = (device) => {
    setCurrentDevice({
      id: device.id,
      name: device.name,
      type: device.type,
      serialNumber: device.serialNumber || '',
      manufacturer: device.manufacturer || '',
      status: device.status || 'operational',
      installDate: device.installDate || '',
      calibrationDate: device.calibrationDate || '',
      nextMaintenanceDate: device.nextMaintenanceDate || '',
      notes: device.notes || '',
    });
    setFormErrors({});
    setDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentDevice({
      ...currentDevice,
      [name]: value,
    });
    
    // Clear error when field is filled
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    }
  };
  
  const handleSaveDevice = () => {
    if (!validateForm()) return;
    
    setLoading(true);
    const updatedEquipment = currentDevice.id
      ? equipment.map(device => device.id === currentDevice.id ? { ...currentDevice } : device)
      : [...equipment, { ...currentDevice, id: Date.now().toString() }];
    
    dispatch(updateBranchModalities({
      branchId: selectedBranch,
      modalities: updatedEquipment,
    }))
      .then(() => {
        setEquipment(updatedEquipment);
        setDialogOpen(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  
  const handleDeleteDevice = (deviceId) => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      const updatedEquipment = equipment.filter(device => device.id !== deviceId);
      
      dispatch(updateBranchModalities({
        branchId: selectedBranch,
        modalities: updatedEquipment,
      }))
        .then(() => {
          setEquipment(updatedEquipment);
        });
    }
  };
  
  const validateForm = () => {
    const errors = {};
    if (!currentDevice.name.trim()) errors.name = 'Name is required';
    if (!currentDevice.type) errors.type = 'Modality type is required';
    if (!currentDevice.serialNumber.trim()) errors.serialNumber = 'Serial number is required';
    if (!currentDevice.manufacturer.trim()) errors.manufacturer = 'Manufacturer is required';
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const getStatusColor = (status) => {
    const statusItem = DEVICE_STATUS.find(item => item.value === status);
    return statusItem ? statusItem.color : 'default';
  };
  
  const getModalityLabel = (type) => {
    const modalityType = MODALITY_TYPES.find(item => item.value === type);
    return modalityType ? modalityType.label : type;
  };
  
  const getStatusLabel = (status) => {
    const statusItem = DEVICE_STATUS.find(item => item.value === status);
    return statusItem ? statusItem.label : status;
  };
  
  const modalityCount = MODALITY_TYPES.reduce((acc, type) => {
    acc[type.value] = equipment.filter(device => device.type === type.value).length;
    return acc;
  }, {});
  
  if (loading) {
    return <LoadingSpinner />;
  }
  
  return (
    <Box sx={{ p: 2, position: 'relative' }}>
      <Card sx={{ mb: 4, borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Modality Equipment Management
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={openAddDialog}
              disabled={!selectedBranch}
            >
              Add Device
            </Button>
          </Box>
          
          <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Select Branch</InputLabel>
                <Select
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  label="Select Branch"
                >
                  {getRes?.data && getRes.data.length > 0 ? (
                    getRes.data.map((branch) => (
                      <MenuItem key={branch._id} value={branch._id}>
                        {branch.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="">
                      No branches available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          
          {selectedBranch ? (
            <>
              {equipment.length > 0 ? (
                <>
                  <Box sx={{ mb: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {MODALITY_TYPES.map((type) => (
                      <Box
                        key={type.value}
                        sx={{
                          p: 2,
                          borderRadius: 2,
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          minWidth: 120,
                        }}
                      >
                        <Typography variant="h6" fontWeight={600} color="primary">
                          {modalityCount[type.value] || 0}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {type.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  
                  <TableContainer 
                    component={Paper} 
                    sx={{ 
                      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)', 
                      borderRadius: 2,
                      overflow: 'hidden'
                    }}
                  >
                    <Table>
                      <TableHead sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600 }}>Device Name</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Type</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Manufacturer</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Serial Number</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>Next Maintenance</TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'center' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {equipment.map((device) => (
                          <TableRow 
                            key={device.id}
                            hover
                            sx={{ 
                              '&:nth-of-type(odd)': { 
                                backgroundColor: theme.palette.mode === 'dark' 
                                  ? alpha(theme.palette.common.white, 0.03)
                                  : alpha(theme.palette.common.black, 0.02)
                              }
                            }}
                          >
                            <TableCell sx={{ fontWeight: 500 }}>{device.name}</TableCell>
                            <TableCell>{getModalityLabel(device.type)}</TableCell>
                            <TableCell>{device.manufacturer}</TableCell>
                            <TableCell>{device.serialNumber}</TableCell>
                            <TableCell>
                              <StatusChip 
                                label={getStatusLabel(device.status)} 
                                color={getStatusColor(device.status)}
                                devicestatus={device.status}
                                size="small"
                                sx={{ fontWeight: 500 }}
                              />
                            </TableCell>
                            <TableCell>
                              {device.nextMaintenanceDate 
                                ? new Date(device.nextMaintenanceDate).toLocaleDateString() 
                                : 'N/A'}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip title="Edit Device">
                                  <IconButton 
                                    size="small" 
                                    color="primary"
                                    onClick={() => openEditDialog(device)}
                                    sx={{ mx: 0.5 }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Device">
                                  <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => handleDeleteDevice(device.id)}
                                    sx={{ mx: 0.5 }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <Typography color="text.secondary">
                    No equipment found for this branch. Add new equipment to get started.
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="text.secondary">
                Please select a branch to manage equipment.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      
      {/* Add/Edit Device Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        maxWidth="md" 
        fullWidth
        scroll="paper"
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
            maxHeight: '90vh',
            position: 'absolute',
            top: '5vh'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid', 
          borderColor: theme.palette.divider,
          px: 3,
          py: 2,
          backgroundColor: theme.palette.background.paper
        }}>
          <Typography variant="h6" fontWeight={600}>
            {currentDevice.id ? 'Edit Device' : 'Add New Device'}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ 
          px: 3, 
          py: 3, 
          backgroundColor: theme.palette.background.default,
          overflowY: 'auto' 
        }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Device Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                name="name"
                placeholder="Enter device name"
                value={currentDevice.name}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!formErrors.name}
                helperText={formErrors.name}
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderColor: formErrors.name ? 'red' : '#bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Modality Type <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl 
                fullWidth 
                required 
                error={!!formErrors.type}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderColor: formErrors.type ? 'red' : '#bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              >
                <Select
                  name="type"
                  value={currentDevice.type}
                  onChange={handleInputChange}
                  displayEmpty
                  renderValue={selected => {
                    if (!selected) {
                      return <span style={{ color: '#757575' }}>Select modality type</span>;
                    }
                    return getModalityLabel(selected);
                  }}
                >
                  {MODALITY_TYPES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.type && <FormHelperText sx={{ color: 'red' }}>{formErrors.type}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Serial Number <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                name="serialNumber"
                placeholder="Enter serial number"
                value={currentDevice.serialNumber}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!formErrors.serialNumber}
                helperText={formErrors.serialNumber}
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderColor: formErrors.serialNumber ? 'red' : '#bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Manufacturer <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                name="manufacturer"
                placeholder="Enter manufacturer"
                value={currentDevice.manufacturer}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!formErrors.manufacturer}
                helperText={formErrors.manufacturer}
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderColor: formErrors.manufacturer ? 'red' : '#bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Status
              </Typography>
              <FormControl 
                fullWidth
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                    border: '1px solid #bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              >
                <Select
                  name="status"
                  value={currentDevice.status || 'operational'}
                  onChange={handleInputChange}
                >
                  {DEVICE_STATUS.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box 
                          sx={{ 
                            width: 12, 
                            height: 12, 
                            borderRadius: '50%', 
                            backgroundColor: theme.palette[status.color].main,
                            mr: 1 
                          }} 
                        />
                        {status.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Installation Date
              </Typography>
              <TextField
                name="installDate"
                type="date"
                value={currentDevice.installDate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid #bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Last Calibration Date
              </Typography>
              <TextField
                name="calibrationDate"
                type="date"
                value={currentDevice.calibrationDate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid #bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Next Maintenance Date
              </Typography>
              <TextField
                name="nextMaintenanceDate"
                type="date"
                value={currentDevice.nextMaintenanceDate || ''}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid #bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Notes
              </Typography>
              <TextField
                name="notes"
                placeholder="Enter notes"
                value={currentDevice.notes || ''}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                InputProps={{
                  sx: {
                    backgroundColor: 'white',
                    border: '1px solid #bcbcbc',
                    borderRadius: 1,
                    '&:hover': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused': {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                      boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          px: 3, 
          py: 2, 
          borderTop: '1px solid', 
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.background.paper
        }}>
          <Button 
            onClick={handleCloseDialog} 
            color="inherit"
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{ borderColor: '#bcbcbc', color: theme.palette.text.primary }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSaveDevice} 
            color="primary" 
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={16} /> : <SaveIcon />}
          >
            {currentDevice.id ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const EquipmentManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(setMainHeading("Equipment Management"))
  }, [dispatch]);
  
  return (
    <>
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: "center",
          mb: 3, 
          mt: 2
        }}
      >
        <Button className='commonBtn' variant="contained" onClick={() => navigate("/admin/settings")}>
          Back
        </Button>
      </Box>
      <ModalityEquipmentSettings />
    </>
  );
};

export default EquipmentManagement; 