import * as React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, Divider, Link, IconButton, Container } from '@mui/material/';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/Support';
import EmailIcon from '@mui/icons-material/Email';
import SchoolIcon from '@mui/icons-material/School';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import mainImage from "../../assets/images/main-image.png";

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  "&:hover": {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
  }
}));

const CardIconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '12px',
  padding: '12px',
  marginBottom: '16px',
  display: 'inline-flex',
  color: theme.palette.primary.main,
}));

const DocumentCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  "&:hover": {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '10px 24px',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.btncolor.main,
    opacity: 0.9,
  }
}));

const GradientHero = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, rgba(105, 203, 249, 0.2) 100%)`,
  borderRadius: '16px',
  padding: '48px',
  marginBottom: '64px',
  [theme.breakpoints.down('sm')]: {
    padding: '32px 24px',
  }
}));

const Home = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const documents = [
    { 
      title: 'User Guide', 
      icon: <DescriptionIcon fontSize="large" />, 
      description: 'Complete documentation for Voxxil RIS users',
      link: 'https://docs.google.com/document/d/1gquS_jsMexu0aLwfVYP8N9ICukZ_W9-fCy3oOxd-FNQ/edit?tab=t.0'
    },
    { 
      title: 'Admin Manual', 
      icon: <SettingsIcon fontSize="large" />, 
      description: 'System administration and configuration guide',
      link: 'https://docs.google.com/document/d/1gquS_jsMexu0aLwfVYP8N9ICukZ_W9-fCy3oOxd-FNQ/edit?tab=t.0'
    },
    { 
      title: 'Video Tutorials', 
      icon: <SchoolIcon fontSize="large" />, 
      description: 'Step-by-step video tutorials for all features',
      link: '#'
    },
    { 
      title: 'Feature Updates', 
      icon: <AddCircleIcon fontSize="large" />, 
      description: 'Latest additions and improvements to the system',
      link: 'https://voxxil.gy'
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <GradientHero id="hero">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography 
              variant="h3" 
              component="h1" 
              sx={{ 
                color: 'primary.dark', 
                fontWeight: 700, 
                mb: 2,
                fontSize: { xs: '2rem', md: '2.5rem' }
              }}
            >
              Welcome to Voxxil RIS
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'text.primary', 
                mb: 3,
                fontWeight: 500,
                fontSize: { xs: '1.1rem', md: '1.3rem' }
              }}
            >
              Your modern Radiology Information System designed for efficiency, accuracy, and exceptional patient care
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
              <ActionButton 
                variant="contained" 
                color="btncolor" 
                size="large"
                endIcon={<LoginIcon />}
                onClick={handleLogin}
                sx={{ flex: { xs: '1 0 100%', sm: '0 1 auto' }, mb: { xs: 1, sm: 0 } }}
              >
                Login to System
              </ActionButton>
              <ActionButton 
                variant="outlined" 
                color="primary" 
                size="large"
                endIcon={<LanguageIcon />}
                component={Link}
                href="https://voxxil.gy"
                target="_blank"
                rel="noopener"
                sx={{ flex: { xs: '1 0 100%', sm: '0 1 auto' } }}
              >
                Visit voxxil.gy
              </ActionButton>
                </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={mainImage}
              alt="Voxxil RIS Dashboard"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '12px',
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
              }}
            />
          </Grid>
            </Grid>
      </GradientHero>

      <Box sx={{ mb: 8 }}>
        <Typography 
          variant="h4" 
          component="h2" 
          sx={{ 
            color: 'primary.dark', 
            fontWeight: 700, 
            mb: 4,
            textAlign: 'center'
          }}
        >
          Quick Access
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StyledCard>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}>
                <CardIconWrapper>
                  <DashboardIcon fontSize="large" />
                </CardIconWrapper>
                <Typography variant="h6" component="h3" sx={{ mb: 2, fontWeight: 600 }}>
                  Dashboard
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Access your personalized dashboard with analytics and patient data
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <IconButton
                    color="primary"
                    onClick={handleLogin}
                    sx={{ 
                      backgroundColor: 'primary.light', 
                      '&:hover': { backgroundColor: 'primary.main', color: 'white' } 
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <StyledCard>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}>
                <CardIconWrapper>
                  <DescriptionIcon fontSize="large" />
                </CardIconWrapper>
                <Typography variant="h6" component="h3" sx={{ mb: 2, fontWeight: 600 }}>
                  Documentation
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Explore comprehensive guides and learning resources
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <IconButton
                    color="primary"
                    onClick={() => handleScrollTo('documentation')}
                    sx={{ 
                      backgroundColor: 'primary.light', 
                      '&:hover': { backgroundColor: 'primary.main', color: 'white' } 
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <StyledCard>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}>
                <CardIconWrapper>
                  <SupportIcon fontSize="large" />
                </CardIconWrapper>
                <Typography variant="h6" component="h3" sx={{ mb: 2, fontWeight: 600 }}>
                  Support
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Get technical assistance from our support team
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <IconButton
                    color="primary"
                    component={Link}
                    href="mailto:contact@voxxil.gy"
                    sx={{ 
                      backgroundColor: 'primary.light', 
                      '&:hover': { backgroundColor: 'primary.main', color: 'white' } 
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <StyledCard>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flexGrow: 1 }}>
                <CardIconWrapper>
                  <LanguageIcon fontSize="large" />
                </CardIconWrapper>
                <Typography variant="h6" component="h3" sx={{ mb: 2, fontWeight: 600 }}>
                  Website
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Visit our official website for more information
                </Typography>
                <Box sx={{ mt: 'auto' }}>
                  <IconButton
                    color="primary"
                    component={Link}
                    href="https://voxxil.gy"
                    target="_blank"
                    rel="noopener"
                    sx={{ 
                      backgroundColor: 'primary.light', 
                      '&:hover': { backgroundColor: 'primary.main', color: 'white' } 
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </StyledCard>
            </Grid>
          </Grid>
      </Box>

      <Box id="documentation" sx={{ mb: 8 }}>
        <Typography 
          variant="h4" 
          component="h2" 
          sx={{ 
            color: 'primary.dark', 
            fontWeight: 700, 
            mb: 1,
            textAlign: 'center'
          }}
        >
          Documentation
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary', 
            mb: 4,
            textAlign: 'center'
          }}
        >
          Access the resources you need to get the most out of Voxxil RIS
        </Typography>
        <Grid container spacing={3}>
          {documents.map((doc, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <DocumentCard>
                <CardContent sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <Box sx={{ 
                    color: 'btncolor.main', 
                    backgroundColor: 'primary.light', 
                    p: 1.5,
                    borderRadius: '8px'
                  }}>
                    {doc.icon}
                </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="h3" sx={{ mb: 1, fontWeight: 600 }}>
                      {doc.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      {doc.description}
                    </Typography>
                    <Link 
                      href={doc.link} 
                      color="primary"
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 500 }}
                    >
                      View Document
                      <ArrowForwardIcon fontSize="small" />
                    </Link>
                  </Box>
                </CardContent>
              </DocumentCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ mb: 5 }} />

      <Box id="support" sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 3, fontWeight: 600, color: 'primary.dark' }}>
          Need Help?
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ 
              p: 3, 
              backgroundColor: 'primary.light', 
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
              gap: 1
            }}>
              <EmailIcon fontSize="large" color="primary" />
              <Typography variant="h6" sx={{ fontWeight: 600 }}>Support Email</Typography>
              <Link 
                href="mailto:contact@voxxil.gy" 
                color="primary"
                underline="hover"
                sx={{ fontWeight: 500 }}
              >
                contact@voxxil.gy
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ 
              p: 3, 
              backgroundColor: 'primary.light', 
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
              gap: 1
            }}>
              <EmailIcon fontSize="large" color="primary" />
              <Typography variant="h6" sx={{ fontWeight: 600 }}>Book an Appointment</Typography>
              <Link 
                href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3ZWHu-wbgbKc9c3sEhfFD4-5iIkT3hu7gS-X2keno2-3L4-ACeGr9GECRlsAS_80D6vEy8NOR2" 
                color="primary"
                underline="hover"
                sx={{ fontWeight: 500 }}
              >
                Booking Page
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} Voxxil RIS. All rights reserved.
        </Typography>
      </Box> */}
    </Container>
  );
};

export default Home;