import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";

// Enhanced styled search field
const SearchTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    '&.Mui-focused': {
      boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
    },
  },
}));

export default function PatientTabHeader({ handleSearchCallback }) {
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Simple debounce for search input
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchCallback("name", searchText);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [searchText, handleSearchCallback]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Immediate search on form submit
    handleSearchCallback("name", searchText);
  };

  const clearSearch = () => {
    setSearchText('');
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 2, md: 3 }, 
        mb: 3, 
        borderRadius: 2,
        backgroundColor: theme.palette.background.default,
        border: '1px solid',
        borderColor: theme.palette.divider
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        spacing={2}
        mb={isMobile ? 1 : 0}
      >
        <Typography 
          variant="subtitle1" 
          fontWeight={600}
          sx={{ 
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            '&::before': {
              content: '""',
              display: 'inline-block',
              width: '4px',
              height: '18px',
              backgroundColor: theme.palette.primary.main,
              borderRadius: '2px',
              marginRight: '8px',
            }
          }}
        >
          Patient Directory
        </Typography>
        
        <Box sx={{ width: { xs: '100%', sm: '60%', md: '50%', lg: '40%' } }}>
          <form onSubmit={handleSubmit}>
            <SearchTextField
              placeholder="Search patients by name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: searchText && (
                  <InputAdornment position="end">
                    <IconButton 
                      edge="end" 
                      onClick={clearSearch}
                      size="small"
                    >
                      ✕
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Box>
      </Stack>
    </Paper>
  );
}
