import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '../redux/modules/auth/authSlice';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { SESSION_TIMEOUT, WARNING_BEFORE_TIMEOUT } from '../constants/config';

// Convert minutes to milliseconds
const INACTIVITY_TIMEOUT = SESSION_TIMEOUT * 60 * 1000;
// Warning timeout before logout
const WARNING_TIMEOUT = INACTIVITY_TIMEOUT - (WARNING_BEFORE_TIMEOUT * 60 * 1000);

const InactivityMonitor = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const timerRef = useRef(null);
  const warningTimerRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);

  // Use useCallback to memoize the resetTimer function
  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    
    if (warningTimerRef.current) {
      clearTimeout(warningTimerRef.current);
    }
    
    if (isLoggedIn) {
      // Set warning timer
      warningTimerRef.current = setTimeout(() => {
        setShowWarning(true);
      }, WARNING_TIMEOUT);
      
      // Set logout timer
      timerRef.current = setTimeout(() => {
        setShowWarning(false);
        toast.info('You have been logged out due to inactivity');
        dispatch(logoutRequest());
      }, INACTIVITY_TIMEOUT);
    }
  }, [isLoggedIn, dispatch]);

  const handleContinue = () => {
    setShowWarning(false);
    resetTimer();
  };

  useEffect(() => {
    // Only setup the inactivity timer if the user is logged in
    if (isLoggedIn) {
      // Reset the timer on user activity
      const events = [
        'mousedown', 'mousemove', 'keypress',
        'scroll', 'touchstart', 'click', 'keydown'
      ];
      
      // Initial setup of the timer
      resetTimer();
      
      // Add event listeners
      events.forEach(event => {
        document.addEventListener(event, resetTimer);
      });
      
      // Cleanup when component unmounts or when login state changes
      return () => {
        events.forEach(event => {
          document.removeEventListener(event, resetTimer);
        });
        
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        
        if (warningTimerRef.current) {
          clearTimeout(warningTimerRef.current);
        }
      };
    } else {
      setShowWarning(false);
    }
  }, [isLoggedIn, resetTimer]);

  return (
    <Dialog
      open={showWarning}
      onClose={handleContinue}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Session Timeout Warning"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session is about to expire due to inactivity. You will be logged out in {WARNING_BEFORE_TIMEOUT} minute{WARNING_BEFORE_TIMEOUT > 1 ? 's' : ''}.
          Would you like to continue your session?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleContinue} color="primary" autoFocus>
          Continue Session
        </Button>
        <Button onClick={() => dispatch(logoutRequest())} color="error">
          Logout Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivityMonitor; 