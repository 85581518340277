import { BrowserRouter } from "react-router-dom";
import Routing from "./routing";
import CustomTheme from "./theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InactivityMonitor from "./component/InactivityMonitor";
import NavigationListener from "./component/NavigationListener";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <CustomTheme>
            <NavigationListener />
            <Routing />
            <InactivityMonitor />
          </CustomTheme>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;