import ListFormHoc from "../../../../utils/listFormHoc";
import List from "./list";
import Form from "./form";
import { useEffect } from "react";
import { setMainHeading } from "../../../../redux/modules/mainHeading/slice";
import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Branches() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        dispatch(setMainHeading("Branches"))
    }, [dispatch])
    
    // Render both Back and Add buttons in the same container
    const renderActionButtons = (actionButtonProps) => (
        <Box 
            sx={{ 
                display: "flex", 
                justifyContent: "space-between", 
                alignItems: "center",
                mb: 3, 
                mt: 2
            }}
        >
            <Button className='commonBtn' variant="contained" onClick={() => navigate("/admin/settings")}>
                Back
            </Button>
            <Button 
                className='commonBtn' 
                variant="contained" 
                onClick={actionButtonProps.onClick} 
                startIcon={actionButtonProps.icon}
            >
                {actionButtonProps.text}
            </Button>
        </Box>
    );
    
    return (
        <ListFormHoc 
            List={List} 
            Form={Form} 
            renderActionButtons={renderActionButtons}
        />
    );
}
