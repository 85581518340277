import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    mainHeading: "Welcome back!",
};

const slice = createSlice({
    name: 'mainHeading',
    initialState,
    reducers: {
        setMainHeading : (state, action) => {
            state.mainHeading = action.payload;
        },
        resetMainHeading: (state) => {
            state.mainHeading = initialState.mainHeading;
        }
    }
});


export const { setMainHeading, resetMainHeading } = slice.actions;

export default slice.reducer;