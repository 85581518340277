import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../config/apiConfig";

// Get modalities for a branch
export const getBranchModalities = createAsyncThunk(
  "settings/getBranchModalities",
  async (branchId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/api/equipment/${branchId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update modalities for a branch
export const updateBranchModalities = createAsyncThunk(
  "settings/updateBranchModalities",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/api/equipment/${data.branchId}`, {
        modalities: data.modalities
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    modalities: {
      data: [],
      loading: false,
      error: null
    }
  },
  reducers: {
  },
  extraReducers: (builder) => {
    // Get Branch Modalities
    builder.addCase(getBranchModalities.pending, (state) => {
      state.modalities.loading = true;
      state.modalities.error = null;
    });
    builder.addCase(getBranchModalities.fulfilled, (state, action) => {
      state.modalities.loading = false;
      state.modalities.data = action.payload;
    });
    builder.addCase(getBranchModalities.rejected, (state, action) => {
      state.modalities.loading = false;
      state.modalities.error = action.payload || "Failed to fetch modalities";
    });

    // Update Branch Modalities
    builder.addCase(updateBranchModalities.pending, (state) => {
      state.modalities.loading = true;
      state.modalities.error = null;
    });
    builder.addCase(updateBranchModalities.fulfilled, (state, action) => {
      state.modalities.loading = false;
      state.modalities.data = action.payload;
    });
    builder.addCase(updateBranchModalities.rejected, (state, action) => {
      state.modalities.loading = false;
      state.modalities.error = action.payload || "Failed to update modalities";
    });
  }
});

export default settingsSlice.reducer; 