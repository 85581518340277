import { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import SideNav from "../pages/adminDashboard/component/sideNav";
import Header from "../pages/adminDashboard/component/header";
const drawerWidth = 400;

const AdminLayout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex', minHeight: "100vh", width: "100%", maxWidth: "100%", overflow: "hidden" }}>
            <Header drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
            <SideNav drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
            <Box 
                component="main" 
                sx={{ 
                    flexGrow: 1, 
                    p: { xs: 2, sm: 3, md: 3, lg: 3, xl: 3 },
                    width: { sm: `calc(100% - ${drawerWidth}px)` }, 
                    maxWidth: "100%", 
                    overflowX: "hidden",
                    boxSizing: "border-box",
                    pr: { xs: 4, sm: 4, md: 4, lg: 5, xl: 6 } // Increased right padding to account for scrollbar
                }}
            >
                <Toolbar sx={{ marginBottom: 5 }} className="main-toolbar" />
                {children}
            </Box>
        </Box>
    )
}

export default AdminLayout