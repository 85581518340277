import styled from "@emotion/styled";
import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, Radio, Table, TableBody, TableHead, TableRow, Typography, Divider, Container, Chip, TableContainer } from "@mui/material"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useEffect, useState, useRef } from "react";
import { getIdRequest } from "../../../redux/modules/radiology/slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import PrintIcon from '@mui/icons-material/Print';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '12px 16px',
        fontSize: '0.95rem',
        fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '12px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    '& svg': {
        marginRight: theme.spacing(1),
    },
}));

const PreviewRadiology = ({ setPreviewRadiology, itemData }) => {
    const dispatch = useDispatch();
    let { getIdRes } = useSelector(state => state.radiology);
    const [radiologyData, setRadiologyData] = useState({});
    const contentRef = useRef(null);

    useEffect(() => {
        if (itemData?.id) {
            dispatch(getIdRequest(itemData.id));
        }
    }, [dispatch, itemData])

    useEffect(() => {
        if (!getIdRes?.success) {
            toast(getIdRes?.message)
        }

        if (getIdRes?.data?._id) {
            setRadiologyData(getIdRes?.data)
        }
    }, [getIdRes, setPreviewRadiology]);

    const handlePrint = () => {
        const printWindow = window.open('', '_blank', 'width=1000,height=800');
        
        // Create a clean print-friendly view
        printWindow.document.write(`
            <html>
            <head>
                <title>Radiology Report</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        line-height: 1.6;
                        color: #333;
                        margin: 0;
                        padding: 20px;
                    }
                    h1 {
                        color: #1976d2;
                        font-size: 24px;
                        border-bottom: 2px solid #1976d2;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                    }
                    h2.section-title {
                        color: #1976d2;
                        font-size: 18px;
                        margin-top: 25px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #e0e0e0;
                        padding-bottom: 5px;
                    }
                    .section {
                        margin-bottom: 30px;
                    }
                    .grid-container {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 15px;
                    }
                    @media (max-width: 768px) {
                        .grid-container {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                    @media (max-width: 480px) {
                        .grid-container {
                            grid-template-columns: 1fr;
                        }
                    }
                    .grid-item {
                        margin-bottom: 10px;
                    }
                    .label {
                        font-weight: bold;
                        color: #666;
                        margin-bottom: 3px;
                    }
                    .value {
                        font-weight: normal;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 20px;
                    }
                    th {
                        background-color: #f5f5f5;
                        border: 1px solid #ddd;
                        padding: 12px 15px;
                        text-align: left;
                        font-weight: bold;
                        color: #333;
                    }
                    td {
                        border: 1px solid #ddd;
                        padding: 12px 15px;
                        text-align: left;
                    }
                    tr:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                    .checkbox {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #999;
                        margin-right: 5px;
                        text-align: center;
                    }
                    .checkbox.checked:after {
                        content: "✓";
                    }
                    .footer {
                        margin-top: 40px;
                        border-top: 1px solid #eee;
                        padding-top: 15px;
                        text-align: center;
                        font-size: 12px;
                        color: #777;
                    }
                </style>
            </head>
            <body>
                <h1>Radiology Report</h1>
                
                <div class="section">
                    <h2 class="section-title">Appointment Details</h2>
                    <div class="grid-container">
                        <div class="grid-item">
                            <div class="label">Category:</div>
                            <div class="value">${radiologyData?.appoinmentId?.appoinmentCategory || "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Start Date & Time:</div>
                            <div class="value">${radiologyData?.appoinmentId?.startTime ? moment.utc(radiologyData?.appoinmentId?.startTime).format('MM/DD/YYYY hh:mm A') : "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">End Date & Time:</div>
                            <div class="value">${radiologyData?.appoinmentId?.endTime ? moment.utc(radiologyData?.appoinmentId?.endTime).format('MM/DD/YYYY hh:mm A') : "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Referring Consultant / GP:</div>
                            <div class="value">${radiologyData?.appoinmentId?.referId || "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Pregnant:</div>
                            <div class="value">${radiologyData?.appoinmentId?.isPregnant ? "Yes" : "No"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">LMP:</div>
                            <div class="value">${radiologyData?.appoinmentId?.isPregnant ? `${radiologyData?.appoinmentId?.lmp} week ago` : "-"}</div>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <h2 class="section-title">Patient Information</h2>
                    <div class="grid-container">
                        <div class="grid-item">
                            <div class="label">First Name:</div>
                            <div class="value">${radiologyData?.patientId?.fName || "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Last Name:</div>
                            <div class="value">${radiologyData?.patientId?.lName || "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Email:</div>
                            <div class="value">${radiologyData?.patientId?.email || "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">D.O.B:</div>
                            <div class="value">${radiologyData?.patientId?.dob ? moment(radiologyData?.patientId?.dob).format('MM/DD/YYYY') : "-"}</div>
                        </div>
                        <div class="grid-item">
                            <div class="label">Gender:</div>
                            <div class="value">${radiologyData?.patientId?.gender ? radiologyData?.patientId?.gender.charAt(0).toUpperCase() + radiologyData?.patientId?.gender.slice(1) : "-"}</div>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <h2 class="section-title">Examination Details</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Exam</th>
                                <th>Modality</th>
                                <th>Group</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${radiologyData?.examList?.length > 0 ?
                                radiologyData.examList.map((item, index) => {
                                    if (item?.examListModality && Array.isArray(item?.examListModality) && item?.examListModality?.length > 0) {
                                        return item.examListModality.map((elem, i) => `
                                            <tr>
                                                <td>${elem?.examName || "-"}</td>
                                                <td>${elem?.modalityName ? `${elem.modalityName}${elem.modalityDescription ? ` (${elem.modalityDescription})` : ''}` : "-"}</td>
                                                <td>${item?.group?.name || "-"}</td>
                                            </tr>
                                        `).join('');
                                    }
                                    return '';
                                }).join('') 
                                : 
                                `<tr><td colspan="3" style="text-align: center; padding: 15px;">No examination data available</td></tr>`
                            }
                        </tbody>
                    </table>
                </div>
                
                <div class="section">
                    <h2 class="section-title">Special Instructions & Notes</h2>
                    <div style="display: flex; flex-wrap: wrap; gap: 20px;">
                        <div style="flex: 1; min-width: 300px;">
                            <h3 style="margin-top: 0; color: #1976d2;">Safety Checklist</h3>
                            <p>
                                <label>
                                    <span class="checkbox ${radiologyData?.isCorrectPatient ? 'checked' : ''}"></span>
                                    Correct Patient
                                </label>
                                ${radiologyData?.correctPatientValue ? `<div style="margin-left: 25px;">${radiologyData?.correctPatientValue}</div>` : ''}
                            </p>
                            <p>
                                <label>
                                    <span class="checkbox ${radiologyData?.isCorrectSide ? 'checked' : ''}"></span>
                                    Correct Side
                                </label>
                                ${radiologyData?.correctSideValue ? `<div style="margin-left: 25px;">${radiologyData?.correctSideValue}</div>` : ''}
                            </p>
                            <p>
                                <label>
                                    <span class="checkbox ${radiologyData?.isCorrectProcedure ? 'checked' : ''}"></span>
                                    Correct Procedure
                                </label>
                                ${radiologyData?.correctProcedureValue ? `<div style="margin-left: 25px;">${radiologyData?.correctProcedureValue}</div>` : ''}
                            </p>
                        </div>
                        <div style="flex: 1; min-width: 300px;">
                            <h3 style="margin-top: 0; color: #1976d2;">Additional Information</h3>
                            <p>
                                <span style="font-weight: 600; color: #666;">Contrast Required:</span>
                                ${radiologyData?.contrastValue === "true" ? "Yes" : "No"}
                            </p>
                            <p>
                                <span style="font-weight: 600; color: #666;">Allergies/Infection Status:</span>
                                ${radiologyData?.allergiesDetails || "-"}
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <h2 class="section-title">Staff Information</h2>
                    
                    <div style="margin-bottom: 20px; border: 1px solid #eee; padding: 15px; border-radius: 4px;">
                        <h3 style="margin-top: 0; color: #1976d2;">Referring Clinical</h3>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="flex: 2; min-width: 200px; margin-right: 20px;">
                                <p><span style="font-weight: 600; color: #666;">Name:</span> 
                                ${radiologyData?.referringSignatureId ? 
                                    `${radiologyData?.referringSignatureId?.firstName || ''} ${radiologyData?.referringSignatureId?.lastName || ''}` : 
                                    "-"
                                }</p>
                            </div>
                            <div style="flex: 1; min-width: 150px;">
                                <p><span style="font-weight: 600; color: #666;">Date:</span> 
                                ${radiologyData?.referringDate ? moment.utc(radiologyData?.referringDate).format('MM/DD/YYYY') : "-"}</p>
                            </div>
                        </div>
                        ${radiologyData?.isReferringSignatureVerified && radiologyData?.referringSignatureId?._id && radiologyData?.referringSignatureId?.signatureImage ? 
                            `<div style="margin-top: 10px;">
                                <p style="font-weight: 600; color: #666; margin-bottom: 5px;">Signature:</p>
                                <div style="border: 1px dashed #ccc; padding: 5px; border-radius: 4px; background: #f9f9f9; display: inline-block;">
                                    <img height="60" src="${process.env.REACT_APP_SERVER_API}api/profile/${radiologyData?.referringSignatureId?._id}/signatureImage/${radiologyData?.referringSignatureId?.signatureImage}" alt="signature" />
                                </div>
                            </div>` : ''
                        }
                    </div>
                    
                    <div style="margin-bottom: 20px; border: 1px solid #eee; padding: 15px; border-radius: 4px;">
                        <h3 style="margin-top: 0; color: #1976d2;">Authorization & Operation</h3>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="flex: 1; min-width: 200px; margin-right: 10px;">
                                <p><span style="font-weight: 600; color: #666;">Authorized By:</span> 
                                ${radiologyData?.authorisedId ? 
                                    `${radiologyData?.authorisedId?.firstName || ''} ${radiologyData?.authorisedId?.lastName || ''}` : 
                                    "-"
                                }</p>
                            </div>
                            <div style="flex: 1; min-width: 200px;">
                                <p><span style="font-weight: 600; color: #666;">Authorized Date:</span> 
                                ${radiologyData?.referringDate ? moment.utc(radiologyData?.referringDate).format('MM/DD/YYYY') : "-"}</p>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="flex: 1; min-width: 200px; margin-right: 10px;">
                                <p><span style="font-weight: 600; color: #666;">Operator/(MIT):</span> 
                                ${radiologyData?.operatorId ? 
                                    `${radiologyData?.operatorId?.firstName || ''} ${radiologyData?.operatorId?.lastName || ''}` : 
                                    "-"
                                }</p>
                            </div>
                            <div style="flex: 1; min-width: 200px;">
                                <p><span style="font-weight: 600; color: #666;">Operator Date:</span> 
                                ${radiologyData?.operatorDate ? moment.utc(radiologyData?.operatorDate).format('MM/DD/YYYY') : "-"}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div style="border: 1px solid #eee; padding: 15px; border-radius: 4px;">
                        <h3 style="margin-top: 0; color: #1976d2;">Appointer Information</h3>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="flex: 1; min-width: 200px; margin-right: 10px;">
                                <p><span style="font-weight: 600; color: #666;">Appointer:</span> 
                                ${radiologyData?.appointerId ? 
                                    `${radiologyData?.appointerId?.firstName || ''} ${radiologyData?.appointerId?.lastName || ''}` : 
                                    "-"
                                }</p>
                            </div>
                        </div>
                        ${radiologyData?.isAppointerSignatureVerified && radiologyData?.appointerId?._id && radiologyData?.appointerId?.signatureImage ? 
                            `<div style="margin-top: 10px;">
                                <p style="font-weight: 600; color: #666; margin-bottom: 5px;">Signature:</p>
                                <div style="border: 1px dashed #ccc; padding: 5px; border-radius: 4px; background: #f9f9f9; display: inline-block;">
                                    <img height="60" src="${process.env.REACT_APP_SERVER_API}api/profile/${radiologyData?.appointerId?._id}/signatureImage/${radiologyData?.appointerId?.signatureImage}" alt="signature" />
                                </div>
                            </div>` : ''
                        }
                    </div>
                </div>
                
                <div class="footer">
                    Report generated on ${moment().format('MM/DD/YYYY hh:mm A')}
                </div>
            </body>
            </html>
        `);
        
        // Print the window
        printWindow.document.close();
        
        // Set up a listener for if the print window is closed without printing
        const checkWindowClosed = setInterval(() => {
            if (printWindow.closed) {
                clearInterval(checkWindowClosed);
            }
        }, 500);
        
        // Print after content is loaded
        printWindow.addEventListener('load', () => {
            printWindow.focus();
            printWindow.print();
            
            // If user closes print dialog, still keep the window open for them to manually close
            // The window will not auto-close to avoid issues with button functionality in the main window
        });
    };

    return (
        <Box ref={contentRef} sx={{ py: 3, px: { xs: 2, md: 3 } }}>
            <Container maxWidth="lg">
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2, mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>Radiology Order Report</Typography>
                        <Box>
                            <Chip 
                                icon={<EventIcon />} 
                                label={moment().format('MM/DD/YYYY')} 
                                variant="outlined" 
                                sx={{ mr: 1 }}
                            />
                            <Chip 
                                label={`Order ID: ${radiologyData?.orderId || "-"}`} 
                                color="primary"
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 3 }} />

            <Box id="print-content">
                        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, border: '1px solid #e0e0e0' }} elevation={0}>
                            <SectionTitle variant="h6">
                                <EventIcon />
                                Appointment Details
                            </SectionTitle>
                            <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Category</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.appoinmentId?.appoinmentCategory || "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Start Date & Time</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {radiologyData?.appoinmentId?.startTime ? moment.utc(radiologyData?.appoinmentId?.startTime).format('MM/DD/YYYY hh:mm A') : "-"}
                                    </Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>End Date & Time</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {radiologyData?.appoinmentId?.endTime ? moment.utc(radiologyData?.appoinmentId?.endTime).format('MM/DD/YYYY hh:mm A') : "-"}
                                    </Typography>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Referring Consultant / GP</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.appoinmentId?.referId ? `${radiologyData?.appoinmentId?.referId}` : "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Pregnant</Typography>
                                    <Chip 
                                        size="small"
                                        label={radiologyData?.appoinmentId?.isPregnant ? "Yes" : "No"}
                                        color={radiologyData?.appoinmentId?.isPregnant ? "warning" : "default"}
                                    />
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>LMP</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.appoinmentId?.isPregnant ? `${radiologyData?.appoinmentId?.lmp} week ago` : "-"}</Typography>
                        </Grid>
                    </Grid>
                </Paper>

                        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, border: '1px solid #e0e0e0' }} elevation={0}>
                            <SectionTitle variant="h6">
                                <PersonIcon />
                                Patient Information
                            </SectionTitle>
                            <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>First Name</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.patientId?.fName || "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Last Name</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.patientId?.lName || "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Email</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{radiologyData?.patientId?.email || "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>D.O.B</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500 }}> {radiologyData?.patientId?.dob ? moment(radiologyData?.patientId?.dob).format('MM/DD/YYYY') : "-"}</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>Gender</Typography>
                                    <Typography variant="body1" sx={{ fontWeight: 500, textTransform: 'capitalize' }}>{radiologyData?.patientId?.gender || "-"}</Typography>
                        </Grid>
                    </Grid>
                </Paper>

                        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, border: '1px solid #e0e0e0' }} elevation={0}>
                            <SectionTitle variant="h6">
                                <MedicalInformationIcon />
                                Examination Details
                            </SectionTitle>
                            <TableContainer sx={{ borderRadius: 1, overflow: 'hidden', mb: 2 }}>
                                <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tick/Exam</StyledTableCell>
                                <StyledTableCell>Modality</StyledTableCell>
                                <StyledTableCell>Group</StyledTableCell>
                            </TableRow>
                        </TableHead>
                                    <TableBody>
                            {radiologyData?.examList?.length > 0 ?
                                radiologyData?.examList?.map((item, index) => (
                                                item?.examListModality && Array.isArray(item?.examListModality) && item?.examListModality?.length > 0 &&
                                    item?.examListModality?.map(elem => (
                                                    <StyledTableRow key={`${elem?._id || index}-${index}`}>
                                            <StyledTableCell align="left">
                                                            <Typography variant="body2">{elem?.examName || "-"}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                            <Typography variant="body2">{elem?.modalityName ? `${elem.modalityName}${elem.modalityDescription ? ` (${elem.modalityDescription})` : ''}` : "-"}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                            <Typography variant="body2">{item?.group?.name || "-"}</Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                ))
                                :
                                <StyledTableRow>
                                                <StyledTableCell colSpan={3} align="center">
                                                    <Typography sx={{ py: 2 }}>No examination data available</Typography>
                                                </StyledTableCell>
                                </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                            </TableContainer>
                </Paper>

                        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, border: '1px solid #e0e0e0' }} elevation={0}>
                            <SectionTitle variant="h6">
                                <AssignmentIcon />
                                Special Instructions & Notes
                            </SectionTitle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                                        <Typography variant="subtitle2" color="primary.dark" gutterBottom>Safety Checklist</Typography>
                                        <Box sx={{ mb: 1 }}>
                                            <FormControlLabel 
                                                control={<Checkbox checked={radiologyData?.isCorrectPatient || false} />} 
                                                label="Correct Patient"
                                                sx={{ '& .MuiFormControlLabel-label': { fontWeight: 500 } }}
                                            />
                                            {radiologyData?.correctPatientValue && (
                                                <Typography variant="body2" sx={{ ml: 4 }}>{radiologyData?.correctPatientValue}</Typography>
                                            )}
                                    </Box>
                                        <Box sx={{ mb: 1 }}>
                                            <FormControlLabel 
                                                control={<Checkbox checked={radiologyData?.isCorrectSide || false} />} 
                                                label="Correct Side"
                                                sx={{ '& .MuiFormControlLabel-label': { fontWeight: 500 } }} 
                                            />
                                            {radiologyData?.correctSideValue && (
                                                <Typography variant="body2" sx={{ ml: 4 }}>{radiologyData?.correctSideValue}</Typography>
                                            )}
                                </Box>
                                    <Box>
                                            <FormControlLabel 
                                                control={<Checkbox checked={radiologyData?.isCorrectProcedure || false} />} 
                                                label="Correct Procedure"
                                                sx={{ '& .MuiFormControlLabel-label': { fontWeight: 500 } }} 
                                            />
                                            {radiologyData?.correctProcedureValue && (
                                                <Typography variant="body2" sx={{ ml: 4 }}>{radiologyData?.correctProcedureValue}</Typography>
                                            )}
                                    </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
                                        <Typography variant="subtitle2" color="primary.dark" gutterBottom>Additional Information</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>Contrast Required</Typography>
                                            <Chip 
                                                size="small"
                                                label={radiologyData?.contrastValue === "true" ? "Yes" : "No"}
                                                color={radiologyData?.contrastValue === "true" ? "primary" : "default"}
                                            />
                                </Box>
                                    <Box>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>Allergies/Infection Status</Typography>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>{radiologyData?.allergiesDetails || "-"}</Typography>
                                    </Box>
                                    </Paper>
                            </Grid>
                            </Grid>
                        </Paper>

                        <Paper sx={{ p: 3, mb: 3, borderRadius: 2, border: '1px solid #e0e0e0' }} elevation={0}>
                            <SectionTitle variant="h6">Staff Information</SectionTitle>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" sx={{ p: 2 }}>
                                        <Typography variant="subtitle2" color="primary.dark" gutterBottom>Referring Clinical</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Name</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.referringSignatureId ? 
                                                        `${radiologyData?.referringSignatureId?.firstName || ''} ${radiologyData?.referringSignatureId?.lastName || ''}` : 
                                                        "-"
                                                    }
                                                </Typography>
                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Date</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.referringDate ? moment.utc(radiologyData?.referringDate).format('MM/DD/YYYY') : "-"}
                                                </Typography>
                            </Grid>
                                            {radiologyData?.isReferringSignatureVerified && radiologyData?.referringSignatureId?._id && radiologyData?.referringSignatureId?.signatureImage && (
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>Signature</Typography>
                                                    <Box sx={{ border: '1px dashed #ccc', p: 1, borderRadius: 1, background: '#f9f9f9' }}>
                                                        <img 
                                                            height="60" 
                                                            src={`${process.env.REACT_APP_SERVER_API}api/profile/${radiologyData?.referringSignatureId?._id}/signatureImage/${radiologyData?.referringSignatureId?.signatureImage}`} 
                                                            alt="signature" 
                                                        />
                                </Box>
                            </Grid>
                                            )}
                        </Grid>
                </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Paper variant="outlined" sx={{ p: 2 }}>
                                        <Typography variant="subtitle2" color="primary.dark" gutterBottom>Authorization & Operation</Typography>
                    <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Authorized By</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.authorisedId ? 
                                                        `${radiologyData?.authorisedId?.firstName || ''} ${radiologyData?.authorisedId?.lastName || ''}` : 
                                                        "-"
                                                    }
                                                </Typography>
                        </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Authorized Date</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.referringDate ? moment.utc(radiologyData?.referringDate).format('MM/DD/YYYY') : "-"}
                                                </Typography>
                        </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Operator/(MIT)</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.operatorId ? 
                                                        `${radiologyData?.operatorId?.firstName || ''} ${radiologyData?.operatorId?.lastName || ''}` : 
                                                        "-"
                                                    }
                                                </Typography>
                        </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Operator Date</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.operatorDate ? moment.utc(radiologyData?.operatorDate).format('MM/DD/YYYY') : "-"}
                                                </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Paper variant="outlined" sx={{ p: 2 }}>
                                        <Typography variant="subtitle2" color="primary.dark" gutterBottom>Appointer Information</Typography>
                    <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>Appointer</Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {radiologyData?.appointerId ? 
                                                        `${radiologyData?.appointerId?.firstName || ''} ${radiologyData?.appointerId?.lastName || ''}` : 
                                                        "-"
                                                    }
                                                </Typography>
                        </Grid>
                                            {radiologyData?.isAppointerSignatureVerified && radiologyData?.appointerId?._id && radiologyData?.appointerId?.signatureImage && (
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>Appointer Signature</Typography>
                                                    <Box sx={{ border: '1px dashed #ccc', p: 1, borderRadius: 1, background: '#f9f9f9' }}>
                                                        <img 
                                                            height="60" 
                                                            src={`${process.env.REACT_APP_SERVER_API}api/profile/${radiologyData?.appointerId?._id}/signatureImage/${radiologyData?.appointerId?.signatureImage}`} 
                                                            alt="signature" 
                                                        />
                                                    </Box>
                        </Grid>
                                            )}
                    </Grid>
                </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

                    <Box mt={4}>
                        <Divider sx={{ mb: 3 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button 
                                variant="outlined" 
                                startIcon={<ArrowBackIcon />} 
                                onClick={() => setPreviewRadiology()} 
                                sx={{ fontWeight: 500 }}
                            >
                                Back
                            </Button>
                            <Button 
                                variant="contained" 
                                startIcon={<PrintIcon />} 
                                onClick={handlePrint}
                                sx={{ fontWeight: 500 }}
                            >
                                Print Report
                </Button>
                        </Box>
            </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default PreviewRadiology;