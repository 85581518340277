import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMainHeading } from "../../redux/modules/mainHeading/slice";
import { 
  Box, 
  Grid, 
  LinearProgress, 
  MenuItem, 
  Paper, 
  Select, 
  Typography, 
  Button, 
  Container,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Avatar,
  Divider,
  Tabs,
  Tab,
  Chip,
  Badge,
  useTheme,
  alpha
} from "@mui/material";
import { styled } from '@mui/system';
import { getRequest } from "../../redux/modules/dashboard/slice";
import { getdeclineReferralList } from "../../redux/modules/dashboard/slice";
import { generateFullDate, getTime } from "../../helper/dateTime";
import { convertToTitleCase } from "../../helper/stringHelper";
import DeclinedOrderDetails from "./component/declinedOrderDetails";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningIcon from '@mui/icons-material/Warning';
import InsightsIcon from '@mui/icons-material/Insights';
import { keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BarChartIcon from '@mui/icons-material/BarChart';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

// Animation keyframe
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled components for modern dashboard
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderRadius: '12px',
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.08)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  overflow: 'hidden',
  flexGrow: 1,
  "&:hover": {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.12)',
  }
}));

const GradientCard = styled(Card)(({ theme, color }) => ({
  height: '100%',
  width: '100%',
  borderRadius: '12px',
  background: color ? 
    `linear-gradient(135deg, ${alpha(color, 0.15)} 0%, ${alpha(color, 0.05)} 100%)` : 
    `linear-gradient(135deg, ${theme.palette.primary.light} 0%, rgba(105, 203, 249, 0.2) 100%)`,
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.08)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  flexGrow: 1,
  "&:hover": {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.12)',
  }
}));

const MetricIcon = styled(Box)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '12px',
  backgroundColor: alpha(color || theme.palette.primary.main, 0.12),
  color: color || theme.palette.primary.main,
  marginBottom: '16px'
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '8px 16px',
    fontSize: '0.875rem',
    borderRadius: '8px', 
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: alpha(theme.palette.primary.main, 0.2),
  }
}));

const StatChip = styled(Chip)(({ theme, trend }) => ({
  backgroundColor: trend === 'up' 
    ? alpha(theme.palette.success.main, 0.1)
    : alpha(theme.palette.error.main, 0.1),
  color: trend === 'up' ? theme.palette.success.main : theme.palette.error.main,
  fontWeight: 500,
  '& .MuiChip-icon': {
    color: 'inherit'
  }
}));

// Tab panel component for dashboard tabs
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-tabpanel-${index}`}
      aria-labelledby={`dashboard-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [viewRadiologyModal, setViewRadiologyModal] = useState({
    openViewRadiologyModal: false,
    orderDetails: {}
  });
  
  // Filter states
  const [timeRange, setTimeRange] = useState('7days');
  
  // Redux state
  const { getRes, declineReferral } = useSelector(state => state.dashboard);
  const currentBranch = useSelector(state => state.branch.currentBranch);

  useEffect(() => {
    dispatch(setMainHeading("Welcome back!"));
    
    // Important: Clean up when component unmounts
    return () => {
      // This ensures that when we navigate away, we don't leave stale heading state
      // The NavigationListener will reset the heading, allowing the next page to set it correctly
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentBranch?.id && currentBranch?.id.length > 0) {
      dispatch(getRequest());
      dispatch(getdeclineReferralList());
    }
  }, [currentBranch?.id, dispatch]);

  const displayOrderDetailHandler = async (order) => {
    setViewRadiologyModal({
      openViewRadiologyModal: true,
      orderDetails: order
    });
  };

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(getRequest());
    dispatch(getdeclineReferralList());
    
    // Simulate refresh completion after 1 second
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };

  // Navigation handlers for quick actions
  const handleNavigateToPatients = () => navigate('/admin/patient-management');
  const handleNavigateToScheduling = () => navigate('/admin/appointment');
  const handleNavigateToReferrals = () => navigate('/admin/referred-list');
  const handleNavigateToReporting = () => navigate('/admin/analytics-reporting');
  const handleNavigateToAnalytics = () => navigate('/admin/billing-invoice');

  return (
    <Container 
      maxWidth={false}
      sx={{ 
        width: '100%', 
        px: { xs: 2, sm: 3, md: 4, lg: 4, xl: 5 },
        mx: 0
      }}
    >
      <DeclinedOrderDetails 
        viewRadiologyModal={viewRadiologyModal}
        setViewRadiologyModal={setViewRadiologyModal}
      />
      
      {/* Dashboard Header */}
      <Box sx={{ mb: 3, mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 700, color: 'primary.dark' }}>
            Radiology Operations Center
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <IconButton 
            onClick={handleRefresh}
            sx={{ 
              borderRadius: '8px',
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.2) }
            }}
          >
            {refreshing ? 
              <RefreshIcon color="primary" sx={{ animation: `${spin} 1s linear infinite` }} /> : 
              <RefreshIcon color="primary" />
            }
          </IconButton>
        </Box>
      </Box>

      {/* Dashboard Content */}
      <Box>
        {/* Quick Stats/Metrics */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <GradientCard color={theme.palette.primary.main}>
              <CardContent sx={{ p: 3 }}>
                <MetricIcon color={theme.palette.primary.main}>
                  <PeopleIcon fontSize="medium" />
                </MetricIcon>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Total Patients
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: 'text.primary' }}>
                    {getRes?.totalPatientsCount || '0'}
                  </Typography>
                  <StatChip 
                    size="small" 
                    trend="up"
                    label="+4.2%" 
                    icon={<TrendingUpIcon fontSize="small" />} 
                  />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {getRes?.newPatients ? `${getRes.newPatients} new patients this week` : 'No new patients this week'}
                </Typography>
              </CardContent>
            </GradientCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <GradientCard color={theme.palette.info.main}>
              <CardContent sx={{ p: 3 }}>
                <MetricIcon color={theme.palette.info.main}>
                  <CalendarMonthIcon fontSize="medium" />
                </MetricIcon>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Today's Exams
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: 'text.primary' }}>
                    {getRes?.totalAppointmentsCount || '0'}
                  </Typography>
                  <StatChip 
                    size="small" 
                    trend="up"
                    label="+2.8%" 
                    icon={<TrendingUpIcon fontSize="small" />} 
                  />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  <Box component="span" sx={{ color: theme.palette.success.main, fontWeight: 500 }}>
                    {getRes?.completedAppointments || '0'}
                  </Box> completed,{' '}
                  <Box component="span" sx={{ color: theme.palette.error.main, fontWeight: 500 }}>
                    {getRes?.missedAppointments || '0'}
                  </Box> missed
                </Typography>
              </CardContent>
            </GradientCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <GradientCard color={theme.palette.warning.main}>
              <CardContent sx={{ p: 3 }}>
                <MetricIcon color={theme.palette.warning.main}>
                  <AssignmentTurnedInIcon fontSize="medium" />
                </MetricIcon>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Pending Reports
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: 'text.primary' }}>
                    28
                  </Typography>
                  <StatChip 
                    size="small" 
                    trend="down"
                    label="-3.5%" 
                    icon={<TrendingDownIcon fontSize="small" />} 
                  />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Avg. turnaround: 32 minutes
                </Typography>
              </CardContent>
            </GradientCard>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <GradientCard color={theme.palette.success.main}>
              <CardContent sx={{ p: 3 }}>
                <MetricIcon color={theme.palette.success.main}>
                  <AssignmentTurnedInIcon fontSize="medium" />
                </MetricIcon>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Study Completion Rate
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: 'text.primary' }}>
                    94.3%
                  </Typography>
                  <StatChip 
                    size="small" 
                    trend="up"
                    label="+2.1%" 
                    icon={<TrendingUpIcon fontSize="small" />} 
                  />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Last 7 days
                </Typography>
              </CardContent>
            </GradientCard>
          </Grid>
        </Grid>

        {/* Modality Utilization */}
        <Box sx={{ mb: 4 }}>
          <StyledCard>
            <CardHeader
              title={
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Equipment Utilization
                </Typography>
              }
              action={
                <StyledSelect
                  size="small"
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                  sx={{ minWidth: 130 }}
                >
                  <MenuItem value="24hours">Last 24 Hours</MenuItem>
                  <MenuItem value="7days">Last 7 Days</MenuItem>
                  <MenuItem value="30days">Last 30 Days</MenuItem>
                </StyledSelect>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                {[
                  { name: 'MRI #1', value: 84, color: theme.palette.primary.main, nextAvailable: '2:30 PM', status: 'Active' },
                  { name: 'CT Scan', value: 76, color: theme.palette.info.main, nextAvailable: '1:15 PM', status: 'Active' },
                  { name: 'X-Ray #1', value: 92, color: theme.palette.success.main, nextAvailable: '12:45 PM', status: 'Active' },
                  { name: 'Ultrasound', value: 68, color: theme.palette.warning.main, nextAvailable: 'Now', status: 'Available' },
                  { name: 'MRI #2', value: 45, color: theme.palette.error.main, nextAvailable: '3:30 PM', status: 'Maintenance' }
                ].map((item, index) => (
                  <Grid item xs={6} sm={4} md={2.4} key={index}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Box sx={{ position: 'relative', mx: 'auto', width: 80, height: 80 }}>
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          background: `conic-gradient(${item.color} ${item.value}%, transparent ${item.value}%)`,
                          transform: 'rotate(-90deg)'
                        }} />
                        <Box sx={{
                          position: 'absolute',
                          top: '10%',
                          left: '10%',
                          width: '80%',
                          height: '80%',
                          borderRadius: '50%',
                          backgroundColor: 'background.paper',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            {item.value}%
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color={
                        item.status === 'Active' ? 'success.main' : 
                        item.status === 'Maintenance' ? 'error.main' : 
                        'info.main'
                      }>
                        {item.status}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Next available: {item.nextAvailable}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </StyledCard>
        </Box>

        {/* Main Content Grid */}
        <Grid container spacing={3}>
          {/* Today's Appointments */}
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Today's Scheduled Exams
                  </Typography>
                }
                action={
                  <IconButton 
                    size="small"
                    onClick={handleRefresh}
                    sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
                  >
                    <RefreshIcon fontSize="small" color="primary" />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ p: 0, maxHeight: '390px', overflowY: 'auto' }}>
                {getRes?.appoinments && getRes?.appoinments.length > 0 ? (
                  getRes.appoinments.map((data, index) => (
                    <Box 
                      key={index}
                      sx={{
                        p: 2,
                        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.7)}`,
                        '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.05) }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Avatar 
                          sx={{ 
                            bgcolor: theme.palette.primary.main,
                            width: 40,
                            height: 40,
                            mr: 2
                          }}
                        >
                          {data?.patientInformation?.fName?.charAt(0) || 'P'}
                        </Avatar>
                        <Box sx={{ flexGrow: 1 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                              {data?.patientInformation?.fName} {data?.patientInformation?.lName}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <Chip 
                                size="small" 
                                label="Check-in" 
                                color="primary" 
                                variant="outlined"
                                sx={{ borderRadius: '4px', fontSize: '0.75rem' }}
                              />
                              <IconButton 
                                size="small" 
                                onClick={() => displayOrderDetailHandler(data)}
                                sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
                              >
                                <VisibilityIcon fontSize="small" color="primary" />
                              </IconButton>
                            </Box>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <AccessTimeIcon 
                                  fontSize="small" 
                                  color="action" 
                                  sx={{ mr: 1, fontSize: '1rem' }} 
                                />
                                <Typography variant="body2" color="text.secondary">
                                  {getTime(data?.appoinments?.startTime)}
                                </Typography>
                              </Box>
                              <Typography variant="body2" color="text.secondary">
                                {generateFullDate(data?.appoinments?.startTime)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 500 }}>
                                Imaging Studies:
                              </Typography>
                              <Typography variant="body2" sx={{ 
                                color: theme.palette.text.primary,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%'
                              }}>
                                {data?.examList
                                  .map((item) => item.examListModality.map((listItem) => listItem?.examName))
                                  .flat()
                                  .join(", ")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ py: 4, textAlign: 'center' }}>
                    <Typography color="text.secondary">
                      No exams scheduled for today
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Critical Results/Alerts */}
          <Grid item xs={12} md={6}>
            <StyledCard>
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Critical Results & Alerts
                  </Typography>
                }
                action={
                  <IconButton 
                    size="small"
                    onClick={handleRefresh}
                    sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
                  >
                    <RefreshIcon fontSize="small" color="primary" />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent sx={{ p: 0, maxHeight: '390px', overflowY: 'auto' }}>
                {declineReferral && declineReferral.length > 0 ? (
                  declineReferral.map((data, index) => (
                    <Box 
                      key={index}
                      sx={{
                        p: 2,
                        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.7)}`,
                        '&:hover': { backgroundColor: alpha(theme.palette.error.main, 0.05) }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Avatar 
                          sx={{ 
                            bgcolor: theme.palette.error.main,
                            width: 40,
                            height: 40,
                            mr: 2
                          }}
                        >
                          <WarningIcon fontSize="small" />
                        </Avatar>
                        <Box sx={{ flexGrow: 1 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                              {data?.patientId?.fName} {data?.patientId?.lName}
                            </Typography>
                            <IconButton 
                              size="small" 
                              onClick={() => displayOrderDetailHandler(data)}
                              sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
                            >
                              <VisibilityIcon fontSize="small" color="primary" />
                            </IconButton>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                <strong>Alert Type:</strong> Referral Denial
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AccessTimeIcon 
                                  fontSize="small" 
                                  color="action" 
                                  sx={{ mr: 1, fontSize: '1rem' }} 
                                />
                                <Typography variant="body2" color="text.secondary">
                                  {generateFullDate(data?.denialTimeString)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2" sx={{ 
                                color: theme.palette.error.main,
                                fontWeight: 500
                              }}>
                                Priority
                              </Typography>
                              <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                startIcon={<VisibilityIcon fontSize="small" />}
                                onClick={() => displayOrderDetailHandler(data)}
                                sx={{ 
                                  mt: 0.5, 
                                  textTransform: 'none',
                                  borderRadius: '8px'
                                }}
                              >
                                View Details
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ py: 4, textAlign: 'center' }}>
                    <Typography color="text.secondary">
                      No critical results or alerts
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Quick Actions */}
          <Grid item xs={12}>
            <StyledCard>
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Quick Actions
                  </Typography>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  {[
                    { icon: <PeopleIcon />, title: "Patient Management", color: theme.palette.primary.main, action: handleNavigateToPatients },
                    { icon: <ScheduleIcon />, title: "Scheduling", color: theme.palette.info.main, action: handleNavigateToScheduling },
                    { icon: <LocalHospitalIcon />, title: "Referrals", color: theme.palette.success.main, action: handleNavigateToReferrals },
                    { icon: <AssignmentIcon />, title: "Reporting", color: theme.palette.warning.main, action: handleNavigateToReporting },
                    { icon: <ReceiptIcon />, title: "Billing", color: theme.palette.error.main, action: handleNavigateToAnalytics }
                  ].map((action, index) => (
                    <Grid item xs={6} sm={4} md={2.4} key={index}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={action.action}
                        startIcon={
                          <Box 
                            sx={{ 
                              backgroundColor: alpha(action.color, 0.1), 
                              p: 1,
                              borderRadius: '8px',
                              color: action.color
                            }}
                          >
                            {action.icon}
                          </Box>
                        }
                        sx={{ 
                          p: 2,
                          height: '100%',
                          borderColor: alpha(action.color, 0.3),
                          color: action.color,
                          justifyContent: 'flex-start',
                          borderRadius: '10px',
                          textTransform: 'none',
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: alpha(action.color, 0.05),
                            borderColor: action.color
                          }
                        }}
                      >
                        {action.title}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminDashboard;